import { useContext, useEffect, useState } from "react";

import { EditorContext } from "../containers/editor/EditorLayout";
import { getRandomString, getVideoMetadataFromUrl, getSecToHMS } from "../_helpers/utils";
import { widgetConfig } from "../components/Editor/editor_config";
import * as constant from "../constants/editor";

const useAddWidget = (addWidgetClicked, assetType, dataAttr, position, widgetWidth, widgetHeight, srcSet) => {
  let { metadata, pageNodes } = useContext(EditorContext);

  const [widgetObject, setWidgetObject] = useState();

  const parseDurationToSeconds = time => {
    let [minute, secondsMilli] = time.split(":");
    let [second, milliSecond] = secondsMilli ? secondsMilli.split(".") : [0, 0];

    let totalSeconds = (minute ? parseInt(minute, 10) * 60 : 0) + (second ? parseFloat(second) : 0);

    if (milliSecond) totalSeconds += parseFloat(`0.${milliSecond.charAt(0)}`) || 0;

    return totalSeconds;
  };

  const modifyDataAttr = async () => {
    if (
      dataAttr["data-asset-type"] === constant.VIDEO &&
      [constant.STOCK, constant.BRAND, constant.UPLOAD_VIDEO].includes(dataAttr["data-source"]) &&
      dataAttr["data-url"]
    ) {
      const { duration } = await getVideoMetadataFromUrl(dataAttr["data-url"]);
      const pageDuration = pageNodes[metadata.activePageIdx].pageDuration;

      const pageDurationSeconds = parseDurationToSeconds(getSecToHMS({ sec: pageDuration }));
      const videoDurationSeconds = parseDurationToSeconds(getSecToHMS({ sec: duration }));

      if (videoDurationSeconds > pageDurationSeconds) {
        dataAttr["data-trimmed-duration"] = getSecToHMS({ sec: pageDuration });
        dataAttr["data-trim"] = `0,${pageDurationSeconds}`;
      }

      dataAttr["data-duration"] = getSecToHMS({ sec: duration });
    }
  };

  useEffect(() => {
    const setupWidget = async () => {
      if (addWidgetClicked && position) {
        let targetWidgetId = "dhp-widget-" + getRandomString(8);
        let activePageId = metadata.activePageId;
        let activeBlockId = metadata.activeBlockId;

        await modifyDataAttr();

        let widgetObj = {
          pageId: activePageId,
          blockId: activeBlockId,
          id: targetWidgetId,
          classLists: ["dhp-page-widget", "dhp-root-widget"],
          duration: {
            startTime: "0.0s",
            endTime: `${pageNodes[metadata.activePageIdx].pageDuration}s`,
            totalTime: `${pageNodes[metadata.activePageIdx].pageDuration}s`,
          },
          style: {
            display: "block",
            position: "absolute",
            zIndex: 50,
            transform: `translate(${position[0].left}px,${position[0].top}px) scale(1, 1) rotate(0deg)`,
            opacity: 1,
            // width:
            //   constant.TEXT === assetType
            //     ? widgetConfig[assetType][dataAttr["data-heading"]].width
            //     : widgetWidth
            //     ? widgetWidth
            //     : widgetConfig[assetType].width,
            // height:
            //   constant.TEXT === assetType
            //     ? widgetConfig[assetType][dataAttr["data-heading"]].height * constant.LINE_HEIGHT
            //     : widgetHeight
            //     ? widgetHeight
            //     : widgetConfig[assetType].height,
            width: widgetWidth
              ? widgetWidth
              : constant.TEXT === assetType
              ? widgetConfig[assetType][dataAttr["data-heading"]].width
              : widgetConfig[assetType].width,
            height: widgetHeight
              ? widgetHeight
              : constant.TEXT === assetType
              ? widgetConfig[assetType][dataAttr["data-heading"]].height * constant.LINE_HEIGHT
              : widgetConfig[assetType].height,
            backfaceVisibility: "inherit",
          },
          data: dataAttr,
          ...(srcSet ? { srcSet } : {}),
        };

        setWidgetObject(widgetObj);
      }
    };
    setupWidget();
  }, [addWidgetClicked, position]);

  return widgetObject;
};

export default useAddWidget;
