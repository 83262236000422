import React, { useContext, useEffect, useState } from "react";
import { DropdownItem, DropdownMenu, DropdownToggle, Input as RInput, UncontrolledDropdown } from "reactstrap";
import cx from "classnames";
import PropTypes from "prop-types";

import global from "../../../scss/dhp.scss";

import AssetName from "./Common/AssetName";
import { Icon } from "../../ui/icon";
import { Input } from "../../ui/input";
import { widgetConfig } from "../editor_config";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import CanvasPageHtmlGenaretor from "../CanvasPageHtmlGenaretor";
import { setResponsiveSrcToAllWidgets, stringTocamelCase } from "../../../_helpers/utils";
import useUpdatePageDuration from "../../../hooks/useUpdatePageDuration";
import { EditorWrapperContext } from "../../../containers/editor/EditorWrapper";

let style = Object.assign({}, global);

const PageTransition = props => {
  let {
    metadata,
    pageNodes,
    updatePageNodes,
    updateTransitionAppliedAllPages,
    blockNodes,
    updateBlockNodes,
    widgets,
    dimension,
  } = useContext(EditorContext);
  let { changeBySocket } = useContext(EditorWrapperContext);

  const [searchKeyWord, setSearchKeyWord] = useState("");
  const [transitionStatus, setTransitionStatus] = useState();
  const [pageTransitionType, setPageTransitionType] = useState();
  const [pageTransitionSpeed, setPageTransitionSpeed] = useState();
  const [isShownApplyToAllPages, setIsShownApplyToAllPages] = useState(false);
  const [isShownAppliedSuccessfully, setIsShownAppliedSuccessfully] = useState(false);
  const [showTransition, setShowTransition] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [updateContext, setUpdateContext] = useState();

  const { transitionSpeedUpdateBasedOnPageDuration } = useUpdatePageDuration();

  const transitionTypeList = widgetConfig.page_transition.types
    .map(availableTransitionType => ({
      ...availableTransitionType,
      options: availableTransitionType?.options?.filter(option =>
        option.name.toLowerCase().includes(searchKeyWord.toLowerCase())
      ),
    }))
    .filter(availableTransitionType => availableTransitionType?.options?.length > 0);

  const togglePageTransition = () => {
    // update enable or disable page transition
    let newArray = Object.assign([...pageNodes], {
      [metadata.activePageIdx]: {
        ...pageNodes[metadata.activePageIdx],
        pageTransition: {
          ...pageNodes[metadata.activePageIdx].pageTransition,
          enabled: !pageNodes[metadata.activePageIdx].pageTransition.enabled,
        },
      },
    });
    updatePageNodes(newArray);
    updateBlockNodes(blockNodes, newArray);
    setTransitionStatus(!pageNodes[metadata.activePageIdx].pageTransition.enabled);

    if (!pageNodes[metadata.activePageIdx].pageTransition.enabled) playAppliedTransition();

    // Check wether all page has applied page transition or if so,same transition type is applied or not
    if (pageNodes.length > 1) {
      let searchNodes = pageNodes.filter(pageNode => pageNode.pageIdx !== metadata.activePageIdx); // remove active page index from pageNode array

      for (let i = 0; i < searchNodes.length; i++) {
        // Switch off the toggle
        if (pageNodes[metadata.activePageIdx].pageTransition.enabled) {
          if (searchNodes[i].pageTransition.enabled === false) {
            setIsShownApplyToAllPages(false); // if all other page transition toggle is off don't show 'Apply to all' message
          } else {
            setIsShownApplyToAllPages(true); // if all any one page transition toggle is on show 'Apply to all' message
            break;
          }
        }
        // Switch on the toggle
        else {
          if (
            searchNodes[i].pageTransition.type === pageNodes[metadata.activePageIdx].pageTransition?.type &&
            searchNodes[i].pageTransition.enabled === !pageNodes[metadata.activePageIdx].pageTransition.enabled
          ) {
            setIsShownApplyToAllPages(false); // if all other page transition toggle is on and transition type are also same don't show 'Apply to all' message
          } else {
            setIsShownApplyToAllPages(true); // if all other page transition toggle is not on and transition type are not same show 'Apply to all' message
            break;
          }
        }
      }
    }
  };

  const toggleTransitionTypeDropdown = () => {
    setDropdownOpen(prevState => !prevState);

    if (dropdownOpen) {
      setSearchKeyWord("");
      return;
    }

    // set dropdown position on selected item
    setTimeout(() => {
      const currentDropdown = document.querySelectorAll(".dropdown-menu-right.show")[0];
      if (currentDropdown) {
        const selectedEl = currentDropdown.querySelectorAll(".active.dropdown-item")[0];
        if (selectedEl) {
          selectedEl.scrollIntoView({ block: "nearest", inline: "start" });
        }
      }
    }, 15);
  };

  const changeTransitionType = type => {
    setUpdateContext(true);
    setPageTransitionType(type);
    setSearchKeyWord("");
    playAppliedTransition();

    // show 'Apply to all pages' as type are changed now
    if (pageNodes.length > 1) {
      setIsShownApplyToAllPages(true);
      updateTransitionAppliedAllPages(false);
    }
  };

  const changeTransitionSpeed = speed => {
    setUpdateContext(true);
    setPageTransitionSpeed(speed);
    playAppliedTransition(speed);
  };

  const applyTransitionToAll = () => {
    let updatedPageNodeList = [];

    // update all pageNodes with active page transition type and transition toggle status
    pageNodes.map((pageNode, index) => {
      let updatedPageNode = {
        ...pageNode,
        pageTransition: {
          ...pageNodes[index].pageTransition,
          enabled: pageNodes[metadata.activePageIdx].pageTransition.enabled,
          type: pageTransitionType,
        },
      };
      updatedPageNodeList.push(updatedPageNode);
    });

    updatePageNodes(updatedPageNodeList);
    updateBlockNodes(blockNodes, updatedPageNodeList);
    setIsShownAppliedSuccessfully(true);

    setTimeout(() => {
      setIsShownAppliedSuccessfully(false);
      setIsShownApplyToAllPages(false); // remove 'Apply to all' message
      updateTransitionAppliedAllPages(true); // upadte context value to track if apply to all transition effect is applied in the doc or not
    }, 2000);
  };

  const checkAllPageTransitionIsSame = () => {
    let searchNodes = pageNodes.filter(pageNode => pageNode.pageIdx !== metadata.activePageIdx); // remove active page index from pageNode array

    for (let i = 0; i < searchNodes.length; i++) {
      if (
        searchNodes[i].pageTransition.type === pageNodes[metadata.activePageIdx].pageTransition?.type &&
        searchNodes[i].pageTransition.enabled === pageNodes[metadata.activePageIdx].pageTransition.enabled
      ) {
        setIsShownApplyToAllPages(false); // if all other page transition toggle is on and transition type are also same don't show 'Apply to all' message
      } else {
        setIsShownApplyToAllPages(true); // if all other page transition toggle is not on and transition type are not same show 'Apply to all' message
        break;
      }
    }
  };

  const playAppliedTransition = speed => {
    let transitionSpeed = speed ? speed : pageNodes[metadata.activePageIdx].pageTransition.speed;
    let timeoutValue =
      parseFloat(
        widgetConfig.page_transition.speeds[
          widgetConfig.page_transition.speeds.findIndex(speed => speed.name === transitionSpeed)
        ].value
      ) * 1000;

    // set the page transition animation status true
    setShowTransition(true);
    setTimeout(() => {
      setShowTransition(false);
    }, timeoutValue);
  };

  useEffect(() => {
    if (transitionStatus) {
      // update pageNodes with updated page transition type
      let newArray = Object.assign([...pageNodes], {
        [metadata.activePageIdx]: {
          ...pageNodes[metadata.activePageIdx],
          pageTransition: {
            ...pageNodes[metadata.activePageIdx].pageTransition,
            type: pageTransitionType,
          },
        },
      });

      if (updateContext) {
        updatePageNodes(newArray);
        updateBlockNodes(blockNodes, newArray);
      }
    }
  }, [pageTransitionType]);

  useEffect(() => {
    if (transitionStatus) {
      // update pageNodes with updated page transition speed
      let newArray = Object.assign([...pageNodes], {
        [metadata.activePageIdx]: {
          ...pageNodes[metadata.activePageIdx],
          pageTransition: {
            ...pageNodes[metadata.activePageIdx].pageTransition,
            speed: pageTransitionSpeed,
          },
        },
      });

      if (updateContext) {
        updatePageNodes(newArray);
        updateBlockNodes(blockNodes, newArray);
      }
    }
  }, [pageTransitionSpeed]);

  useEffect(() => {
    if (pageTransitionType && pageTransitionSpeed) {
      let classname = stringTocamelCase(pageTransitionType);

      if (showTransition) {
        // Add transition class according to transition type
        document.querySelector(".transitionitem figure div").classList.add(classname);

        // Add animation dureation according to transition speed
        document.querySelector(".transitionitem figure div").style["animation-duration"] = `${
          widgetConfig.page_transition.speeds[
            widgetConfig.page_transition.speeds.findIndex(speed => speed.name === pageTransitionSpeed)
          ].value
        }s`;
        setResponsiveSrcToAllWidgets({
          widgets: widgets,
          zoom: Math.floor((220 / dimension.width) * 100),
          parentSelector: `[data-selector="html-generator-page-transition"]`,
        });
      } else {
        if (document.querySelector(".transitionitem figure div"))
          document.querySelector(".transitionitem figure div").classList.remove(classname);
      }
    }
  }, [showTransition]);

  useEffect(() => {
    let activePageTransitionSpeed = transitionSpeedUpdateBasedOnPageDuration();
    setUpdateContext(false);
    setPageTransitionSpeed(activePageTransitionSpeed);
  }, [pageNodes[metadata.activePageIdx].pageDuration]);

  useEffect(() => {
    if (metadata.activePageId) {
      let activePageTransitionSpeed = transitionSpeedUpdateBasedOnPageDuration();

      // check uncheck page transition toggle, when active page id is change
      document.getElementById("customSwitchForPageTransition").checked =
        pageNodes[metadata.activePageIdx].pageTransition.enabled;

      // set transition type and speed, when active page id is change
      setTransitionStatus(pageNodes[metadata.activePageIdx].pageTransition?.enabled);
      setPageTransitionType(pageNodes[metadata.activePageIdx].pageTransition.type);
      setUpdateContext(false);
      setPageTransitionSpeed(activePageTransitionSpeed);

      // set 'Apply to all pages' message, when active page id is change
      if (pageNodes[metadata.activePageIdx].pageTransition.enabled && pageNodes.length > 1)
        checkAllPageTransitionIsSame();
      else setIsShownApplyToAllPages(false);
    }
  }, [metadata.activePageId, pageNodes[metadata.activePageIdx], changeBySocket]); // collabortion of page transition

  useEffect(() => {
    if (pageNodes?.[metadata.activePageIdx]?.pageTransition.enabled) playAppliedTransition();
  }, []);

  return (
    <div className={cx(style["editor-asset-inner"], style["pageTransition-asset"])}>
      <AssetName handleWidgetAction={props.handleWidgetAction} assetName={props.assetName} />

      <div className={cx(style["customScroll"], style["scroll-Y"], style["assets-wrapper"])}>
        {/* Page Transition Toggle */}
        <div className={cx(style["custom-control"], style["custom-switch"], style["mb-4"], style["mx-1"])}>
          <RInput
            type="checkbox"
            className={style["custom-control-input"]}
            defaultChecked={transitionStatus}
            id="customSwitchForPageTransition"
            value={transitionStatus}
            onChange={togglePageTransition}
          />
          <label className={style["custom-control-label"]} htmlFor="customSwitchForPageTransition">
            Apply Transition
          </label>
        </div>

        <div className={cx(style["transition-settings"], style["px-1"])}>
          {/* Display other options if page transition is enable */}
          {transitionStatus && (
            <div className={style["pb-5"]}>
              {/* Transition Types dropdown */}
              <div className={style["line-controls-wrap"]}>
                <div className={style["slidelabel"]}>Type</div>

                <UncontrolledDropdown
                  className={style["border-style"]}
                  isOpen={dropdownOpen}
                  toggle={toggleTransitionTypeDropdown}>
                  <DropdownToggle className={style["text-truncate"]}>
                    {pageTransitionType}
                    <Icon icon="ui-arrow-down" />
                  </DropdownToggle>

                  <DropdownMenu right className={cx(style["shadow-sm"], style["border-0"], style["rounded"])}>
                    <div className={cx(style["search"], style["px-3"], style["pt-2"])}>
                      <Input
                        cssModule={style}
                        returnType="inputGroup"
                        addonType="prepend"
                        type="search"
                        id="search-transition-type"
                        name="search-transition-type"
                        onChange={e => setSearchKeyWord(e.target.value.trim())}
                        autoFocus={true}
                      />
                    </div>

                    <div className={cx(style["customScroll"], style["scroll-Y"])}>
                      {transitionTypeList.length === 0 && (
                        <div className={cx(style["not-found"])}>No results found</div>
                      )}

                      {transitionTypeList.length > 0 &&
                        transitionTypeList.map((availableTransitionType, i) => (
                          <div className={style["mb-2"]} key={i}>
                            <DropdownItem header>{availableTransitionType.groupName}</DropdownItem>

                            {availableTransitionType.options.map(option => (
                              <DropdownItem
                                tag="a"
                                key={option.name}
                                className={cx({
                                  [style["active"]]: option.name === pageTransitionType,
                                })}
                                onClick={() => changeTransitionType(option.name)}>
                                {option.name}
                              </DropdownItem>
                            ))}
                          </div>
                        ))}
                    </div>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>

              {/* Transition Speed dropdown */}
              <div className={cx(style["line-controls-wrap"], style["speed"])}>
                <div className={style["slidelabel"]}>Speed</div>

                <UncontrolledDropdown className={style["border-style"]}>
                  <DropdownToggle>
                    {pageTransitionSpeed}
                    <Icon icon="ui-arrow-down" />
                  </DropdownToggle>

                  <DropdownMenu className={cx(style["shadow-sm"], style["border-0"], style["rounded"])}>
                    {widgetConfig.page_transition.speeds.map((speed, i) => (
                      <DropdownItem
                        tag="a"
                        key={i}
                        onClick={() => changeTransitionSpeed(speed.name)}
                        className={cx(
                          {
                            [style["disabled"]]:
                              parseFloat(speed.value) >= parseFloat(pageNodes?.[metadata.activePageIdx]?.pageDuration),
                          },
                          {
                            [style["active"]]: speed.name === pageTransitionSpeed,
                          }
                        )}>
                        {speed.name} <div className={cx(style["text-muted"], style["ml-2"])}>{speed.value} sec</div>
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>

              {/* Transition Preview of active page */}
              <div
                className={cx(
                  style["mt-4"],
                  style["d-flex"],
                  style["align-items-center"],
                  style["justify-content-between"],
                  style["mb-3"],
                  style["font-15"]
                )}>
                Transition preview
                <div
                  className={cx(
                    style["custom-tooltip"],
                    // style["mr-2"],
                    // style["pr-1"],
                    style["cursor-pointer"],
                    style["d-flex"]
                  )}
                  onClick={() => playAppliedTransition()}>
                  <Icon icon="ui-play2" />
                  <div className={cx(style["custom-tooltip-content"], style["top"])}>Play</div>
                </div>
              </div>
              <div className={cx(style["transition-preview"], style["shadow-sm"], style["rounded"])}>
                <div className="transitionitem" data-selector="html-generator-page-transition">
                  <CanvasPageHtmlGenaretor
                    figureWidth={220}
                    className={"transitionPageList"}
                    pageNode={pageNodes[metadata.activePageIdx]}
                  />
                </div>
              </div>
            </div>
          )}

          {/* Display Apply button if document has more then one page */}
          {pageNodes.length > 1 && isShownApplyToAllPages && (
            <div className={cx(style["mt-auto"], style["text-center"], style["fixed-bottom"])}>
              {isShownAppliedSuccessfully && (
                <span className={cx(style["btn"], style["btn-border"])}>
                  <Icon icon="tick-success" /> Applied Successfully
                </span>
              )}

              {!isShownAppliedSuccessfully && (
                <span className={cx(style["btn"], style["btn-border"])} onClick={applyTransitionToAll}>
                  Apply to All Pages
                </span>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

//Props type validation
PageTransition.propTypes = {
  handleWidgetAction: PropTypes.func.isRequired,
  assetName: PropTypes.string.isRequired,
};

export default PageTransition;
