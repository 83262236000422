import { useContext } from "react";
import { widgetConfig } from "../components/Editor/editor_config";
import { GROUP_WIDGET, GROUP, UNGROUP, TABLE, TEXT } from "../constants/editor";
import { EditorContext } from "../containers/editor/EditorLayout";
import {
  camelCaseToDash,
  checkChildOutsideParent,
  getCssTransformObj,
  getPercentToPixel,
  getPixelToPercent,
  getRandomString,
  getUnScaledValue,
  getWidgetAndParentsDomReference,
  getZoomedValue,
  normalizeAngle,
  rotatePoint,
  setMultiAttrs,
  widgetQuery,
} from "../_helpers/utils";
import UseCheckWidgetAllignment from "./UseCheckWidgetAllignment";
import useDeleteWidget from "./useDeleteWidget";
import useReplaceDuplicateShapeId from "./useReplaceDuplicateShapeId";
import useWidgetHandler from "./useWidgetHandler";

const useGroupUngroup = () => {
  const { metadata, dimension, widgets, updateWidgets, updateMetadata, pageNodes } = useContext(EditorContext);

  const { checkWidgetAllignmentForSingleWidget } = UseCheckWidgetAllignment();
  const setDeleteWidget = useDeleteWidget();
  const { replaceDuplicateIdFromSVG } = useReplaceDuplicateShapeId();
  const { groupSelectionShow: show_GWH } = useWidgetHandler();

  const GU = {
    meta: false,

    checkValidity: ({ type }) => {
      if (type === GROUP) {
        return (
          [...document.querySelectorAll(".group-selected")].filter(w => !w.classList.contains("draggable-disabled"))
            .length >= 2
        );
      }
      if (type === UNGROUP) {
        let groupId;
        const { isSingleWidget, isMultiWidget, data: domRef } = widgetQuery(metadata.activeWidgetId);
        if (isSingleWidget) {
          const {
            widget: {
              id: widgetId,
              isLocked,
              isGroupWidget,
              isChildWidget,
              parent: { id: widgetParentId },
            },
          } = domRef;

          const isApplicable = !isLocked && (isGroupWidget || isChildWidget);
          if (isApplicable) groupId = isChildWidget ? widgetParentId : widgetId;
        }

        if (isMultiWidget) {
          const groupIds = [];
          const singleIds = [];

          metadata.activeWidgetId.forEach(widgetId => {
            if (widgetId.includes("dhp-group")) groupIds.push(widgetId);
            if (widgetId.includes("dhp-widget")) singleIds.push(widgetId);
          });

          if (groupIds.length > 0) GU.handleHybridUngroup({ groupIds, singleIds });
        }

        return groupId;
      }
    },

    handleHybridUngroup: ({ groupIds, singleIds }) => {
      let ungroupedWidgets = [];
      const selectedWidgetIds = [];
      const selectedWidgetTypes = [];

      singleIds.forEach(singleId => {
        selectedWidgetIds.push(singleId);
        selectedWidgetTypes.push(document.getElementById(singleId).dataset.assetType);
      });

      groupIds.forEach((groupId, index) => {
        const childWidgets = GU.ungroup({ groupId, isHybrid: true, updateSelection: index === groupIds.length - 1 });
        ungroupedWidgets = [...ungroupedWidgets, ...childWidgets];
      });

      ungroupedWidgets.forEach(widget => {
        selectedWidgetIds.push(widget.id);
        selectedWidgetTypes.push(document.getElementById(widget.id).dataset.assetType);
      });

      const filteredWidgets = widgets.filter(w => !groupIds.includes(w.id));
      // below func. call order is important (don't change)
      updateMetadata({
        ...metadata,
        activeWidgetId: selectedWidgetIds,
        activeWidgetType: selectedWidgetTypes,
      });

      updateWidgets([...filteredWidgets, ...ungroupedWidgets]);
    },

    getSandBoxElement: ({ html }) => {
      const sandBox = document.createElement("div");
      sandBox.innerHTML = html;
      return sandBox.childNodes[0];
    },

    getFilteredHtml: ({ html }) => {
      const sandBox = document.createElement("div");
      sandBox.innerHTML = html;

      const widget = sandBox.childNodes[0];
      widget.setAttribute("id", `${widget.id}-copy`);

      return sandBox.innerHTML;
    },

    group: ({ isRuntimeOperation } = {}) => {
      if (!isRuntimeOperation) {
        const isApplicable = GU.checkValidity({ type: GROUP });
        if (!isApplicable) return;
      }

      let innerHTML = "";
      let innerWidgetStartTimes = [];
      let innerWidgetEndTimes = [];
      let innerHtmlInPx = "";
      const selectedWidgetIds = [];
      const zoom = dimension.zoom;
      let innerWidgetSrcSet = {};
      const widgetConf = widgetConfig[GROUP_WIDGET];

      const targetWidgetId = "dhp-group-" + getRandomString(8);
      const activePageId = metadata.activePageId;
      const activeBlockId = metadata.activeBlockId;
      const dataAttr = widgetConf.dataAttr;

      const handlerStyle = document.getElementById("dhp-widget-handler")?.style;

      const {
        rotate: { theta: handlerTheta },
        translate: { x: handlerTransX, y: handlerTransY },
      } = getCssTransformObj({
        transform: handlerStyle.transform,
      });

      const {
        transform: widgetTransformStr,
        translate: { x: widgetTransX, y: widgetTransY },
      } = getCssTransformObj({
        translateX: `${getUnScaledValue(handlerTransX, zoom)}px`,
        translateY: `${getUnScaledValue(handlerTransY, zoom)}px`,
        transform: handlerStyle.transform,
        returnHybrid: true,
      });

      const cssObj = {
        width: getUnScaledValue(handlerStyle.width, zoom),
        height: getUnScaledValue(handlerStyle.height, zoom),
        transform: widgetTransformStr,
      };

      document.querySelectorAll(".group-selected").forEach(widget => {
        selectedWidgetIds.push(widget.id);
        if (isRuntimeOperation) {
          let { inPrecent, inPx } = GU.widgetGroupParser(
            widget,
            widgetTransX,
            widgetTransY,
            cssObj,
            isRuntimeOperation
          );
          innerHTML += GU.getFilteredHtml({ html: inPrecent });
          innerHtmlInPx += inPx;
        } else {
          let { inPrecent, inPx } = GU.widgetGroupParser(widget, widgetTransX, widgetTransY, cssObj);
          innerHTML += inPrecent;
          innerHtmlInPx += inPx;
        }

        // push all inner widgets start and end time in an array
        let index = widgets.findIndex(w => w.id === widget.id);
        let widgetStartTime = widgets[index].duration?.startTime ? parseFloat(widgets[index].duration?.startTime) : 0;
        innerWidgetStartTimes.push(widgetStartTime);
        let widgetEndTime = widgets[index].duration?.endTime
          ? parseFloat(widgets[index].duration?.endTime)
          : parseFloat(pageNodes[metadata.activePageIdx].pageDuration);
        innerWidgetEndTimes.push(widgetEndTime);
        // END //
        if (widgets[index]?.data["data-asset-type"] === GROUP_WIDGET && widgets[index]?.groupSrcSet)
          innerWidgetSrcSet = { ...innerWidgetSrcSet, ...widgets[index].groupSrcSet };
        if (widgets[index]?.data["data-asset-type"] !== GROUP_WIDGET && widgets[index]?.srcSet)
          innerWidgetSrcSet = { ...innerWidgetSrcSet, [widget.id]: widgets[index].srcSet };
      });

      // useful for resize/rotate oprations on multiple widgets
      if (isRuntimeOperation) {
        // hide react rendered widgets from DOM
        selectedWidgetIds.forEach(id => document.getElementById(id).classList.add("d-none"));

        // generate runtime group widget and insert into DOM
        const runtimeGroupId = "dhp-runtime-" + getRandomString(8);
        const runtimeGroupWidget = document.createElement("div");
        setMultiAttrs(runtimeGroupWidget, {
          id: runtimeGroupId,
          class: "single-selected dhp-runtime-group",
          style: `display: block; position: absolute; z-index: 50; opacity: 1; width: ${cssObj.width}px; height: ${cssObj.height}px; transform: ${cssObj.transform}`,
          ["data-asset-type"]: GROUP_WIDGET,
          // "data-group-src-set":JSON.stringify(innerWidgetSrcSet),
        });
        runtimeGroupWidget.innerHTML = innerHTML;

        replaceDuplicateIdFromSVG(); // Replace duplicate id for shape use in shape border

        document.getElementById(activeBlockId).appendChild(runtimeGroupWidget);

        return runtimeGroupId;
      }

      // applicable for straight forward group-ungroup operations
      else {
        const { groupSelection, newHtml } = GU.createNewSelection({
          innerHTML: innerHtmlInPx,
          theta: parseInt(handlerTheta),
          widgetTransX,
          widgetTransY,
          cssObj,
          removeAnimation: true,
          returnTrimmedHandler: true,
        });

        const widgetObj = {
          pageId: activePageId,
          blockId: activeBlockId,
          id: targetWidgetId,
          classLists: ["dhp-page-group", "dhp-root-widget"],
          style: {
            display: "block",
            position: "absolute",
            zIndex: 50,
            transform: groupSelection.transform,
            opacity: 1,
            width: groupSelection.width,
            height: groupSelection.height,
          },
          data: { ...dataAttr, "data-value": parseInt(handlerTheta) },
          // Fetch min start time and max end time from inner widgets and set it as group duration
          duration: {
            startTime: `${Math.min(...innerWidgetStartTimes)}s`,
            endTime: `${Math.max(...innerWidgetEndTimes)}s`,
            totalTime: `${(Math.max(...innerWidgetEndTimes) - Math.min(...innerWidgetStartTimes)).toFixed(1)}s`,
          },
          innerHTML: newHtml,
          groupSrcSet:innerWidgetSrcSet
        };

        const filteredWidgets = widgets.filter(w => !selectedWidgetIds.includes(w.id));

        // below func. call order is important (don't change)
        updateWidgets([...filteredWidgets, widgetObj]);

        updateMetadata({
          ...metadata,
          activeWidgetId: [targetWidgetId],
          activeWidgetType: [GROUP_WIDGET],
        });
      }
    },

    ungroup: ({ isRuntimeOperation, groupId, isHybrid, updateSelection } = {}) => {
      if (!isRuntimeOperation && !isHybrid) {
        const isApplicable = GU.checkValidity({ type: UNGROUP });
        if (!isApplicable) return;
        else groupId = isApplicable;
      }

      const childWidgets = [];
      const selectedWidgetIds = [];
      const selectedWidgetTypes = [];
      let childWidgetsHTML = "";

      const activePageId = metadata.activePageId;
      const activeBlockId = metadata.activeBlockId;
      const groupWidgetId = groupId ?? metadata.activeWidgetId[0];
      const groupWidget = document.getElementById(groupWidgetId);
      const groupWidgetIndex = widgets.findIndex(w => w.id === groupWidgetId); //(*****************************Conflict)

      const {
        translate: { x: groupWidgetTransX, y: groupWidgetTransY },
        rotate: { theta: groupWidgetTheta },
      } = getCssTransformObj({
        transform: groupWidget.style.transform,
      });

      const cssObj = {
        width: parseFloat(groupWidget.style.width),
        height: parseFloat(groupWidget.style.height),
        left: parseFloat(groupWidgetTransX),
        top: parseFloat(groupWidgetTransY),
      };

      groupWidget.childNodes.forEach(widget => {
        const widgetId = isRuntimeOperation ? widget.id.replace("-copy", "") : widget.id;
        selectedWidgetIds.push(widgetId);
        selectedWidgetTypes.push(widget.dataset.assetType);

        // format data attributes
        const dataAttr = {};

        // convert % to px and replace left, top with transform
        const {
          rotate: { theta: widgetTheta },
        } = getCssTransformObj({
          transform: widget.style.transform,
        });

        const theta = normalizeAngle(parseInt(widgetTheta) + parseInt(groupWidgetTheta));

        for (let d in widget.dataset) {
          const key = `data-${camelCaseToDash(d)}`;
          const val = widget.dataset[d];
          dataAttr[key] = val === "true" ? true : val === "false" ? false : d === "value" ? theta : val;
        }

        let childCssObj;
        let childWidgetDia = {
          width: getPercentToPixel({ parent: cssObj.width, child: widget.style.width }),
          height: getPercentToPixel({ parent: cssObj.height, child: widget.style.height }),
        };
        const widgetLeftPx = getPercentToPixel({ parent: cssObj.width, child: widget.style.left });
        const widgetTopPx = getPercentToPixel({ parent: cssObj.height, child: widget.style.top });
        const widgetCenterX = parseFloat(groupWidgetTransX) + widgetLeftPx + childWidgetDia.width / 2;
        const widgetCenterY = parseFloat(groupWidgetTransY) + widgetTopPx + childWidgetDia.height / 2;
        const groupCenterX = parseFloat(groupWidgetTransX) + cssObj.width / 2;
        const groupCenterY = parseFloat(groupWidgetTransY) + cssObj.height / 2;
        const rotatedCenter = rotatePoint(
          widgetCenterX,
          widgetCenterY,
          groupCenterX,
          groupCenterY,
          parseInt(groupWidgetTheta)
        );
        const newLeft = rotatedCenter.x - childWidgetDia.width / 2;
        const newTop = rotatedCenter.y - childWidgetDia.height / 2;

        const widgetTransformStr = getCssTransformObj({
          translateX: `${newLeft}px`,
          translateY: `${newTop}px`,
          rotateAngle: `${theta}deg`,
          transform: widget.style.transform,
          returnStr: true,
        });

        childCssObj = {
          width: `${childWidgetDia.width}px`,
          height: `${childWidgetDia.height}px`,
          transform: widgetTransformStr,
        };

        //collect intial childs in px at rotate angle 0
        const sandboxChild = GU.getSandBoxElement({ html: widget.outerHTML });
        sandboxChild.style.height = `${childWidgetDia.height}px`;
        sandboxChild.style.width = `${childWidgetDia.width}px`;
        sandboxChild.style.transform = getCssTransformObj({
          translateX: `${parseFloat(groupWidgetTransX) + widgetLeftPx}px`,
          translateY: `${parseFloat(groupWidgetTransY) + widgetTopPx}px`,
          rotateAngle: `${widgetTheta}`,
          returnStr: true,
        });
        sandboxChild.style.left = "";
        sandboxChild.style.top = "";

        // Check alignment position for each widget under group, when ungroup
        const { x_al, y_al } = checkWidgetAllignmentForSingleWidget(
          childCssObj.height,
          childCssObj.transform,
          childCssObj.width,
          widgetId
        );

        let widgetObj;
        if (isRuntimeOperation) {
          widgetObj = {
            pageId: activePageId,
            blockId: activeBlockId,
            id: widgetId,
            classLists: [...widget.classList, "dhp-root-widget"],
            style: {
              display: widget.style.display,
              position: widget.style.position,
              zIndex: widget.style.zIndex,
              transform: childCssObj.transform,
              opacity: widget.style.opacity,
              width: childCssObj.width,
              height: childCssObj.height,
            },
            data: { ...dataAttr, "data-x-allignment": x_al, "data-y-allignment": y_al },
            innerHTML: widget.innerHTML,
          };
        } else {
          let widgetStartTime = widgets[groupWidgetIndex]?.duration?.startTime
            ? widgets[groupWidgetIndex]?.duration?.startTime
            : 0;
          let widgetEndTime = widgets[groupWidgetIndex]?.duration?.endTime
            ? widgets[groupWidgetIndex].duration.endTime
            : pageNodes[metadata.activePageIdx].pageDuration;

          widgetObj = {
            pageId: activePageId,
            blockId: activeBlockId,
            id: widgetId,
            classLists: [...widget.classList, "dhp-root-widget"],
            style: {
              display: widget.style.display,
              position: widget.style.position,
              zIndex: widget.style.zIndex,
              transform: childCssObj.transform,
              opacity: widget.style.opacity,
              width: childCssObj.width,
              height: childCssObj.height,
            },
            data: { ...dataAttr, "data-x-allignment": x_al, "data-y-allignment": y_al },
            // apply group start time and end time for inner widgets duration (*****************************Conflict)
            duration: {
              startTime: widgetStartTime,
              endTime: widgetEndTime,
              totalTime: `${(parseFloat(widgetEndTime) - parseFloat(widgetStartTime)).toFixed(1)}s`,
            },
            // END //
            innerHTML: widget.innerHTML,
          };
          // inject srcSet if available
          if (widgets[groupWidgetIndex]?.groupSrcSet?.[widgetId])
            widgetObj.srcSet = widgets[groupWidgetIndex].groupSrcSet[widgetId];
        }

        childWidgets.push(widgetObj);
        childWidgetsHTML += sandboxChild.outerHTML;
      });

      if (isHybrid && updateSelection) {
        GU.createNewShiftSelection();
      }
      if (updateSelection) {
        let selectionCss = sessionStorage.getItem("selectionCss");
        if (selectionCss) {
          selectionCss = JSON.parse(selectionCss);
          show_GWH({
            trimmedSelection: selectionCss,
          });
          sessionStorage.removeItem("selectionCss");
        } else {
          show_GWH({
            finalUpdate: false,
          });
        }
      }

      // useful for resize/rotate oprations on multiple widgets
      if (isRuntimeOperation) {
        const isWidgetsOutsideCanvas = checkChildOutsideParent({ parent: `#${activeBlockId}`, child: groupWidget });

        // delete runtime rendered widget from DOM
        groupWidget.remove();

        // display previously hidden widgets (react rendered widgets)
        selectedWidgetIds.forEach(id => document.getElementById(id).classList.remove("d-none"));

        // Check multi widget position during resize/rotate, if all are outside canvas, delete them else upadte their new pos/size
        if (isWidgetsOutsideCanvas) setDeleteWidget(true);
        else {
          // update react dom
          const filteredWidgets = widgets.filter(w => !selectedWidgetIds.includes(w.id));
          // update shift selected widgets duration
          let childWidgetsWithDuration = GU.injectDuration(childWidgets, widgets)
          // update shift selected widgets srcSet
          let childWidgetWithSrcSet = GU.injectSrcSet(childWidgetsWithDuration, widgets)
          updateWidgets([...filteredWidgets, ...childWidgetWithSrcSet]);
        }
      }

      // applicable for straight forward group-ungroup operations
      else {
        if (isHybrid) {
          return childWidgets;
        } else {
          const filteredWidgets = widgets.filter(w => w.id !== groupWidgetId);

          // below func. call order is important (don't change)
          updateMetadata({
            ...metadata,
            activeWidgetId: selectedWidgetIds,
            activeWidgetType: selectedWidgetTypes,
          });
          updateWidgets([...filteredWidgets, ...childWidgets]);
        }
      }
    },

    // create new handlerSelection, groupSelection, newInnerHtml
    createNewSelection: ({
      innerHTML,
      theta,
      widgetTransX,
      widgetTransY,
      cssObj,
      removeAnimation,
      returnTrimmedHandler,
    }) => {
      const sandbox = document.createElement("div");
      sandbox.innerHTML = innerHTML;
      document.getElementById(metadata.activeBlockId).appendChild(sandbox);
      const selectionArea = {
        left: null,
        right: null,
        top: null,
        bottom: null,
      };
      let selected = returnTrimmedHandler
        ? sandbox.querySelectorAll(".dhp-page-widget")
        : sandbox.querySelectorAll(".dhp-root-widget");
      const {
        block: { rect: blockRect },
      } = getWidgetAndParentsDomReference(selected[0].id);
      selected.forEach(widget => {
        const { left, right, top, bottom } =
          widget?.dataset?.assetType === TABLE
            ? widget.querySelector(".dhp-widget-inner .table").getBoundingClientRect()
            : widget?.dataset?.assetType === TEXT
            ? widget.querySelector(".dhp-widget-inner").getBoundingClientRect()
            : widget.getBoundingClientRect();

        if (!selectionArea.top || top < selectionArea.top) selectionArea.top = top;
        if (!selectionArea.left || left < selectionArea.left) selectionArea.left = left;
        if (!selectionArea.right || right > selectionArea.right) selectionArea.right = right;
        if (!selectionArea.bottom || bottom > selectionArea.bottom) selectionArea.bottom = bottom;
      });
      selectionArea.top = selectionArea.top - blockRect.top;
      selectionArea.left = selectionArea.left - blockRect.left;
      selectionArea.right = selectionArea.right - blockRect.left;
      selectionArea.bottom = selectionArea.bottom - blockRect.top;
      let selectionAreaCenter = {
        x: getUnScaledValue(selectionArea.left + (selectionArea.right - selectionArea.left) / 2, dimension.zoom),
        y: getUnScaledValue(selectionArea.top + (selectionArea.bottom - selectionArea.top) / 2, dimension.zoom),
      };
      let handlerCenter = {
        x: parseFloat(widgetTransX) + parseFloat(cssObj.width) / 2,
        y: parseFloat(widgetTransY) + parseFloat(cssObj.height) / 2,
      };
      const rotatedCenter = rotatePoint(
        selectionAreaCenter.x,
        selectionAreaCenter.y,
        handlerCenter.x,
        handlerCenter.y,
        parseInt(theta)
      );
      let newX = getZoomedValue(rotatedCenter.x, dimension.zoom) - (selectionArea.right - selectionArea.left) / 2;
      let newY = getZoomedValue(rotatedCenter.y, dimension.zoom) - (selectionArea.bottom - selectionArea.top) / 2;
      let height = selectionArea.bottom - selectionArea.top;
      let width = selectionArea.right - selectionArea.left;
      const selectionCss = {
        transform: `translate(${newX}px, ${newY}px) scale(1, 1) rotate(${theta}deg)`,
        width: `${width}px`,
        height: `${height}px`,
        left: `${newX}px`,
        top: `${newY}px`,
        theta: `${theta}deg`,
      };
      const selectionCssUnscaled = {
        transform: `translate(${getUnScaledValue(newX, dimension.zoom)}px, ${getUnScaledValue(
          newY,
          dimension.zoom
        )}px) scale(1, 1) rotate(${theta}deg)`,
        width: `${getUnScaledValue(width, dimension.zoom)}px`,
        height: `${getUnScaledValue(height, dimension.zoom)}px`,
        left: `${getUnScaledValue(newX, dimension.zoom)}px`,
        top: `${getUnScaledValue(newY, dimension.zoom)}px`,
        theta: `${theta}deg`,
      };
      let newHtml = "";
      let newStyle = [];
      selected.forEach(widget => {
        let {
          translate: { x: childTransX, y: childTransY },
          rotate: { theta: childTheta },
        } = getCssTransformObj({
          transform: widget.style.transform,
        });
        childTheta = normalizeAngle(parseInt(childTheta));
        let scaleFactor = 1;
        if (widget?.dataset?.assetType === TABLE) {
          let { scale } = getCssTransformObj({
            transform: widget.querySelector(".dhp-widget-inner").style.transform,
          });
          scaleFactor = scale.x;
        }
        let widgetDiaNoRotation = {
          height:
            widget?.dataset?.assetType === TABLE
              ? widget.querySelector(".dhp-widget-inner .table").offsetHeight * scaleFactor
              : widget?.dataset?.assetType === TEXT
              ? widget.querySelector(".dhp-widget-inner").offsetHeight
              : parseFloat(widget.style.height),
          width:
            widget?.dataset?.assetType === TABLE
              ? widget.querySelector(".dhp-widget-inner .table").offsetWidth * scaleFactor
              : widget?.dataset?.assetType === TEXT
              ? widget.querySelector(".dhp-widget-inner").offsetWidth
              : parseFloat(widget.style.width),
        };
        const childCssObj = {
          width: widgetDiaNoRotation.width,
          height: widgetDiaNoRotation.height,
          left: parseFloat(childTransX) - getUnScaledValue(selectionArea.left, dimension.zoom),
          top: parseFloat(childTransY) - getUnScaledValue(selectionArea.top, dimension.zoom),
        };
        // have to calculate new left top as dimention Incresed Or decreased
        const widgetLeftPx = childCssObj.left;
        const widgetTopPx = childCssObj.top;
        const widgetCenterX = widgetLeftPx + childCssObj.width / 2;
        const widgetCenterY = widgetTopPx + childCssObj.height / 2;
        const widgetCenterOldX = widgetLeftPx + parseFloat(widget.style.width) / 2;
        const widgetCenterOldY = widgetTopPx + parseFloat(widget.style.height) / 2;
        const rotatedCenter = rotatePoint(
          widgetCenterX,
          widgetCenterY,
          widgetCenterOldX,
          widgetCenterOldY,
          parseInt(childTheta)
        );
        const newLeft = rotatedCenter.x - childCssObj.width / 2;
        const newTop = rotatedCenter.y - childCssObj.height / 2;
        const sandboxChild = GU.getSandBoxElement({ html: widget.outerHTML });

        let styleInPercent = {
          id: widget.getAttribute("id"),
          width: `${getPixelToPercent({ parent: selectionCssUnscaled.width, child: childCssObj.width })}%`,
          height: `${getPixelToPercent({ parent: selectionCssUnscaled.height, child: childCssObj.height })}%`,
          left: `${getPixelToPercent({ parent: selectionCssUnscaled.width, child: newLeft })}%`,
          top: `${getPixelToPercent({ parent: selectionCssUnscaled.height, child: newTop })}%`,
          transform: `rotate(${childTheta}deg)`,
        };
        newStyle.push(styleInPercent);
        sandboxChild.style.width = styleInPercent.width;
        sandboxChild.style.height = styleInPercent.height;
        sandboxChild.style.left = styleInPercent.left;
        sandboxChild.style.top = styleInPercent.top;
        sandboxChild.style.transform = styleInPercent.transform;
        sandboxChild.setAttribute("data-value", childTheta);
        sandboxChild.classList.remove(
          "dhp-root-widget",
          "single-selected",
          "group-selected",
          "child-selected",
          "drag-selected"
        );

        if (removeAnimation) {
          sandboxChild.removeAttribute("data-entrance-animation");
          sandboxChild.removeAttribute("data-entrance-animation-type");
          sandboxChild.removeAttribute("data-entrance-animation-speed");
          sandboxChild.removeAttribute("data-exit-animation");
          sandboxChild.removeAttribute("data-exit-animation-type");
          sandboxChild.removeAttribute("data-exit-animation-speed");
        }

        newHtml += sandboxChild.outerHTML;
      });
      document.getElementById(metadata.activeBlockId).removeChild(sandbox);
      return { handlerSelection: selectionCss, groupSelection: selectionCssUnscaled, newHtml, newStyle };
    },

    // parse to get new structure
    widgetGroupParser: (widget, widgetTransX, widgetTransY, cssObj, isRuntimeOperation) => {
      const {
        rotate: { theta: groupTheta },
      } = getCssTransformObj({
        transform: cssObj.transform,
      });
      if (widget.dataset.assetType !== GROUP_WIDGET || isRuntimeOperation) {
        // run DOM operations on sandbox instead, direct manipulation
        const sandbox = GU.getSandBoxElement({ html: widget.outerHTML });
        const sandbox2 = GU.getSandBoxElement({ html: widget.outerHTML });
        // get child pos in px
        const {
          translate: { x: childTransX, y: childTransY },
          rotate: { theta: childTheta },
        } = getCssTransformObj({
          transform: widget.style.transform,
        });
        let newCildTheta = parseInt(childTheta) - parseInt(groupTheta);
        const childCssObj = {
          width: parseFloat(widget.style.width),
          height: parseFloat(widget.style.height),
          left: parseFloat(childTransX) - parseFloat(widgetTransX),
          top: parseFloat(childTransY) - parseFloat(widgetTransY),
        };
        const widgetLeftPx = childCssObj.left;
        const widgetTopPx = childCssObj.top;
        const widgetCenterX = widgetLeftPx + childCssObj.width / 2;
        const widgetCenterY = widgetTopPx + childCssObj.height / 2;
        const groupCenterX = cssObj.width / 2;
        const groupCenterY = cssObj.height / 2;
        const rotatedCenter = rotatePoint(
          widgetCenterX,
          widgetCenterY,
          groupCenterX,
          groupCenterY,
          -parseInt(groupTheta)
        );
        const newLeft = rotatedCenter.x - childCssObj.width / 2;
        const newTop = rotatedCenter.y - childCssObj.height / 2;
        // convert px to % and replace transform with left, top
        sandbox.style.width = `${getPixelToPercent({ parent: cssObj.width, child: childCssObj.width })}%`;
        sandbox.style.height = `${getPixelToPercent({ parent: cssObj.height, child: childCssObj.height })}%`;
        sandbox.style.left = `${getPixelToPercent({ parent: cssObj.width, child: newLeft })}%`;
        sandbox.style.top = `${getPixelToPercent({ parent: cssObj.height, child: newTop })}%`;
        sandbox.style.transform = `rotate(${newCildTheta}deg)`;
        // removing "dhp-root-widget" and other classes as they are now part of a group widget
        sandbox.classList.remove(
          "dhp-root-widget",
          "single-selected",
          "group-selected",
          "child-selected",
          "drag-selected"
        );

        const widgetTransformStr = getCssTransformObj({
          translateX: `${newLeft + parseFloat(widgetTransX)}px`,
          translateY: `${newTop + parseFloat(widgetTransY)}px`,
          rotateAngle: `${newCildTheta}deg`,
          returnStr: true,
        });
        sandbox2.style.transform = widgetTransformStr;
        return { inPrecent: sandbox.outerHTML, inPx: sandbox2.outerHTML };
      } else {
        let innerHTML = "";
        let innerHtmlInPx = "";
        const {
          translate: { x: groupWidgetTransX, y: groupWidgetTransY },
          rotate: { theta: groupWidgetTheta },
        } = getCssTransformObj({
          transform: widget.style.transform,
        });
        const cssObjGroup = {
          width: parseFloat(widget.style.width),
          height: parseFloat(widget.style.height),
          left: parseFloat(groupWidgetTransX),
          top: parseFloat(groupWidgetTransY),
        };
        const groupCenterX = cssObjGroup.left + cssObjGroup.width / 2;
        const groupCenterY = cssObjGroup.top + cssObjGroup.height / 2;
        const parentCenterX = parseFloat(widgetTransX) + parseFloat(cssObj.width) / 2;
        const parentCenterY = parseFloat(widgetTransY) + parseFloat(cssObj.height) / 2;
        const innerGroupLeftTop = rotatePoint(
          groupCenterX,
          groupCenterY,
          parentCenterX,
          parentCenterY,
          -parseInt(groupTheta)
        );
        const innerGroupLeft = innerGroupLeftTop.x - parseFloat(cssObjGroup.width) / 2;
        const innerGroupTop = innerGroupLeftTop.y - parseFloat(cssObjGroup.height) / 2;
        const innerGroupTheta = parseInt(groupWidgetTheta) - parseInt(groupTheta);
        widget.childNodes.forEach(child => {
          const sandbox = GU.getSandBoxElement({ html: child.outerHTML });
          const sandboxInPX = GU.getSandBoxElement({ html: child.outerHTML });
          // convert % to px and replace left, top with transform
          const {
            rotate: { theta: childTheta },
          } = getCssTransformObj({
            transform: child.style.transform,
          });
          const effectiveTheta = normalizeAngle(parseInt(childTheta) + parseInt(innerGroupTheta));
          const childWidgetDia = {
            width: getPercentToPixel({ parent: cssObjGroup.width, child: child.style.width }),
            height: getPercentToPixel({ parent: cssObjGroup.height, child: child.style.height }),
          };
          // Calculate the child's position relative to the group widget
          const childLeftPx = getPercentToPixel({ parent: cssObjGroup.width, child: child.style.left });
          const childTopPx = getPercentToPixel({ parent: cssObjGroup.height, child: child.style.top });
          const widgetLeftPx = innerGroupLeft - parseFloat(widgetTransX) + childLeftPx;
          const widgetTopPx = innerGroupTop - parseFloat(widgetTransY) + childTopPx;
          const widgetCenterX = widgetLeftPx + childWidgetDia.width / 2;
          const widgetCenterY = widgetTopPx + childWidgetDia.height / 2;
          const groupCenterX = innerGroupLeft - parseFloat(widgetTransX) + cssObjGroup.width / 2;
          const groupCenterY = innerGroupTop - parseFloat(widgetTransY) + cssObjGroup.height / 2;
          // Rotate the child's center point around the group's center point
          const rotatedCenter = rotatePoint(
            widgetCenterX,
            widgetCenterY,
            groupCenterX,
            groupCenterY,
            parseInt(innerGroupTheta)
          );
          const newLeft = rotatedCenter.x - childWidgetDia.width / 2;
          const newTop = rotatedCenter.y - childWidgetDia.height / 2;
          const childCssObj = {
            left: newLeft,
            top: newTop,
            width: childWidgetDia.width,
            height: childWidgetDia.height,
            rotateAngle: `${effectiveTheta}deg`,
          };
          // convert px to % and replace transform with left, top
          sandbox.style.width = `${getPixelToPercent({ parent: cssObj.width, child: childCssObj.width })}%`;
          sandbox.style.height = `${getPixelToPercent({ parent: cssObj.height, child: childCssObj.height })}%`;
          sandbox.style.left = `${getPixelToPercent({ parent: cssObj.width, child: childCssObj.left })}%`;
          sandbox.style.top = `${getPixelToPercent({ parent: cssObj.height, child: childCssObj.top })}%`;
          sandbox.style.transform = `rotate(${childCssObj.rotateAngle})`;
          // removing "dhp-root-widget" and other classes as they are now part of a group widget
          sandbox.classList.remove(
            "dhp-root-widget",
            "single-selected",
            "group-selected",
            "child-selected",
            "drag-selected"
          );
          innerHTML += sandbox.outerHTML;
          const widgetTransformStr = getCssTransformObj({
            translateX: `${childCssObj.left + parseFloat(widgetTransX)}px`,
            translateY: `${childCssObj.top + parseFloat(widgetTransY)}px`,
            rotateAngle: `${childCssObj.rotateAngle}`,
            returnStr: true,
          });
          sandboxInPX.style.transform = widgetTransformStr;
          sandboxInPX.style.width = `${childCssObj.width}px`;
          sandboxInPX.style.height = `${childCssObj.height}px`;
          sandboxInPX.style.left = "";
          sandboxInPX.style.top = "";
          innerHtmlInPx += sandboxInPX.outerHTML;
        });
        return { inPrecent: innerHTML, inPx: innerHtmlInPx };
      }
    },

    // Re-calculate group-boundry and clilds position, on inner content update (Text/Table)
    adjustChildWidgetsForGroupRotation: wz => {
      let innerHtmlInPxExcludeGroupRotation = "";
      const {
        translate: { x: groupWidgetTransX, y: groupWidgetTransY },
        rotate: { theta: groupWidgetTheta },
      } = getCssTransformObj({
        transform: wz.style.transform,
      });

      const cssObjGroup = {
        width: parseFloat(wz.style.width),
        height: parseFloat(wz.style.height),
        transform: wz.style.transform,
        left: parseFloat(groupWidgetTransX),
        top: parseFloat(groupWidgetTransY),
      };

      wz.querySelectorAll(".dhp-page-widget").forEach(childWidget => {
        //reverse rotate by Group widget theta and collect child pos in px
        const sandbox2 = GU.getSandBoxElement({ html: childWidget.outerHTML });
        const {
          rotate: { theta: childTheta },
        } = getCssTransformObj({
          transform: childWidget.style.transform,
        });

        const childWidgetDia = {
          width: getPercentToPixel({ parent: cssObjGroup.width, child: childWidget.style.width }),
          height: getPercentToPixel({ parent: cssObjGroup.height, child: childWidget.style.height }),
        };

        // Calculate the child's position relative to the group widget
        const childLeftPx = getPercentToPixel({ parent: cssObjGroup.width, child: childWidget.style.left });
        const childTopPx = getPercentToPixel({ parent: cssObjGroup.height, child: childWidget.style.top });

        const widgetLeftPx = parseFloat(groupWidgetTransX) + childLeftPx;
        const widgetTopPx = parseFloat(groupWidgetTransY) + childTopPx;

        const childCssObj = {
          left: widgetLeftPx,
          top: widgetTopPx,
          width: childWidgetDia.width,
          height: childWidgetDia.height,
          rotateAngle: `${childTheta}`,
        };
        const widgetTransformStr = getCssTransformObj({
          translateX: `${childCssObj.left}px`,
          translateY: `${childCssObj.top}px`,
          rotateAngle: `${childCssObj.rotateAngle}`,
          returnStr: true,
        });
        sandbox2.style.transform = widgetTransformStr;
        sandbox2.style.width = `${childCssObj.width}px`;
        sandbox2.style.height = `${childCssObj.height}px`;
        sandbox2.style.left = "";
        sandbox2.style.top = "";
        innerHtmlInPxExcludeGroupRotation += sandbox2.outerHTML;
      });
      const { handlerSelection, groupSelection, newHtml, newStyle } = GU.createNewSelection({
        innerHTML: innerHtmlInPxExcludeGroupRotation,
        theta: parseInt(groupWidgetTheta),
        widgetTransX: cssObjGroup.left,
        widgetTransY: cssObjGroup.top,
        cssObj: cssObjGroup,
        returnTrimmedHandler: true,
      });
      return { handlerSelection, groupSelection, newHtml, newStyle };
    },

    createNewShiftSelection: () => {
      let innerHtmlInPx = "";
      const zoom = dimension.zoom;

      const handlerStyle = document.getElementById("dhp-widget-handler")?.style;

      const {
        rotate: { theta: handlerTheta },
        translate: { x: handlerTransX, y: handlerTransY },
      } = getCssTransformObj({
        transform: handlerStyle.transform,
      });

      const {
        transform: widgetTransformStr,
        translate: { x: widgetTransX, y: widgetTransY },
      } = getCssTransformObj({
        translateX: `${getUnScaledValue(handlerTransX, zoom)}px`,
        translateY: `${getUnScaledValue(handlerTransY, zoom)}px`,
        transform: handlerStyle.transform,
        returnHybrid: true,
      });

      const cssObj = {
        width: getUnScaledValue(handlerStyle.width, zoom),
        height: getUnScaledValue(handlerStyle.height, zoom),
        transform: widgetTransformStr,
      };

      document.querySelectorAll(".group-selected").forEach(widget => {
        let { inPx } = GU.widgetGroupParser(widget, widgetTransX, widgetTransY, cssObj, true);
        innerHtmlInPx += inPx;
      });

      const { handlerSelection } = GU.createNewSelection({
        innerHTML: innerHtmlInPx,
        theta: parseInt(handlerTheta),
        widgetTransX,
        widgetTransY,
        cssObj,
        returnTrimmedHandler: false,
      });

      const { handlerSelection: handlerSelectionTrimmed } = GU.createNewSelection({
        innerHTML: innerHtmlInPx,
        theta: parseInt(handlerTheta),
        widgetTransX,
        widgetTransY,
        cssObj,
        returnTrimmedHandler: true,
      });
      if (handlerSelectionTrimmed) {
        const selectionCss = {
          transform: handlerSelectionTrimmed.transform,
          width: handlerSelectionTrimmed.width,
          height: handlerSelectionTrimmed.height,
        };
        sessionStorage.setItem("selectionCss", JSON.stringify(selectionCss));
      }

      return { handlerSelection };
    },

    // update duration for shift selected widgets
    injectDuration: (arr1, arr2) => {
      return arr1.map(item => {
        const match = arr2.find(e => e.id === item.id);
        if (match) {
          return { ...item, duration: match.duration };
        }
        return item; // return unchanged if no match found
      });
    },

    // update srcSet for shift selected widgets
    injectSrcSet: (arr1, arr2) => {
      return arr1.map(item => {
        const match = arr2.find(e => e.id === item.id);
        if (match) {
          return { ...item, srcSet: match.srcSet };
        }
        return item; // return unchanged if no match found
      });
    }
  };

  return {
    group: GU.group,
    ungroup: GU.ungroup,
    adjustChildWidgetsForGroupRotation: GU.adjustChildWidgetsForGroupRotation,
    getShiftSelection: GU.createNewShiftSelection,
  };
};

export default useGroupUngroup;
