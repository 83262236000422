import { getRandomString } from "../_helpers/utils";

const useReplaceDuplicateShapeId = () => {
  const getNode = element => {
    let node;
    if (element.querySelector(`svg rect`)) {
      node = element.querySelector(`svg rect`);
    } else if (element.querySelector(`svg circle`)) {
      node = element.querySelector(`svg circle`);
    } else if (element.querySelector(`svg polygon`)) {
      node = element.querySelector(`svg polygon`);
    } else if (element.querySelector(`svg path`)) {
      node = element.querySelector(`svg path`);
    }
    return node;
  };

  const updateSVG = (element, borderStyle, borderWidth, borderColor, gradColor) => {
    let gradNode;
    let pathId = getRandomString(8);
    let insidePathId = `inside_${getRandomString(8)}`;
    let node = getNode(element);
    node.setAttribute("id", pathId);
    let newSvgNode = document.createElement("defs");

    if (element.getAttribute("data-grad-scolor") || gradColor) {
      gradNode = element.querySelector(`svg linearGradient`);
      let newGradId = `grad_${getRandomString(8)}`;
      gradNode.setAttribute("id", newGradId);
      node.style.fill = `url(#${newGradId})`;
    }

    if (borderStyle === "solid") {
      //If shape has gradient color inside
      if (gradNode) {
        newSvgNode.innerHTML = `${gradNode.outerHTML}${
          node.outerHTML
        } <clipPath id=${insidePathId}><use xlink:href="#${pathId}"></use></clipPath> <use xlink:href="#${pathId}" stroke-width=${
          parseInt(borderWidth) * 5
        } stroke="${borderColor}" fill="none" clip-path="url(#${insidePathId})"></use>`;
      }
      //If shape has no gradient color inside
      else {
        newSvgNode.innerHTML = `${
          node.outerHTML
        } <clipPath id=${insidePathId}><use xlink:href="#${pathId}"></use></clipPath> <use xlink:href="#${pathId}" stroke-width=${
          parseInt(borderWidth) * 5
        } stroke="${borderColor}" fill="none" clip-path="url(#${insidePathId})"></use>`;
      }
    } else {
      //If shape has gradient color inside
      if (gradNode) {
        newSvgNode.innerHTML = `${gradNode.outerHTML}${
          node.outerHTML
        } <clipPath id=${insidePathId}><use xlink:href="#${pathId}"></use></clipPath> <use xlink:href="#${pathId}" stroke-width=${0} stroke="${borderColor}" fill="none" clip-path="url(#${insidePathId})"></use>`;
      }
      //If shape has no gradient color inside
      else {
        newSvgNode.innerHTML = `${
          node.outerHTML
        } <clipPath id=${insidePathId}><use xlink:href="#${pathId}"></use></clipPath> <use xlink:href="#${pathId}" stroke-width=${0} stroke="${borderColor}" fill="none" clip-path="url(#${insidePathId})"></use>`;
      }
    }
    return newSvgNode.innerHTML;
  };

  const replaceDuplicateIdFromSVG = (targetElems, cloneObj, classname) => {
    let targetElements;

    if (targetElems) {
      let cName = classname ? classname : "dhp-page-widget";
      if (targetElems.querySelectorAll(`.${cName}`)?.length !== 0)
        targetElements = targetElems.querySelectorAll(`.${classname}`);
    } else {
      targetElements = document.querySelectorAll(".dhp-page-widget");
    }

    if (targetElements) {
      targetElements?.forEach(element => {
        if (
          element.getAttribute("data-widget") === "SHAPE" &&
          (element.getAttribute("data-border-style") === "solid" || element.getAttribute("data-grad-scolor"))
        ) {
          let borderStyle = element.getAttribute("data-border-style");
          let borderWidth = element.getAttribute("data-border-width");
          let borderColor = element.getAttribute("data-border-color");
          let newSVG = updateSVG(element, borderStyle, borderWidth, borderColor);

          element.querySelector(`svg`).innerHTML = newSVG;
        }
        if (classname && element.getAttribute("data-widget") === "GROUP") {
          element.querySelectorAll(".dhp-page-widget").forEach(elm => {
            if (
              elm.getAttribute("data-widget") === "SHAPE" &&
              (elm.getAttribute("data-border-style") === "solid" || elm.getAttribute("data-grad-scolor"))
            ) {
              let borderStyle = elm.getAttribute("data-border-style");
              let borderWidth = elm.getAttribute("data-border-width");
              let borderColor = elm.getAttribute("data-border-color");
              let newSVG = updateSVG(elm, borderStyle, borderWidth, borderColor);

              elm.querySelector(`svg`).innerHTML = newSVG;
            }
          });
        }
      });

      document.querySelectorAll(".dhp-widgets")?.forEach(element => {
        if (
          element.getAttribute("data-widget") === "SHAPE" &&
          (element.getAttribute("data-border-style") === "solid" || element.getAttribute("data-grad-scolor"))
        ) {
          let borderStyle = element.getAttribute("data-border-style");
          let borderWidth = element.getAttribute("data-border-width");
          let borderColor = element.getAttribute("data-border-color");
          let newSVG = updateSVG(element, borderStyle, borderWidth, borderColor);

          element.querySelector(`svg`).innerHTML = newSVG;
        }
        if (element.getAttribute("data-widget") === "GROUP") {
          element.querySelectorAll(".dhp-page-widget").forEach(elm => {
            elm.setAttribute("data-id", elm.id);
            elm.removeAttribute("id");
            elm.classList.remove("dhp-page-widget");
            elm.classList.remove("child-selected");
            elm.classList.add("dhp-widgets");
          });
        }
      });

      // add class if text has background color
      document.querySelectorAll(".primary-text-widget").forEach(element => {
        if (element.style.backgroundColor !== "transparent") {
          element.classList.add("text-background-active");
        }
      });
    } else {
      if (cloneObj) {
        let borderStyle = cloneObj.data["data-border-style"];
        let borderWidth = cloneObj.data["data-border-width"];
        let borderColor = cloneObj.data["data-border-color"];
        let gradColor = cloneObj.data["data-grad-scolor"];
        let newSVG = updateSVG(targetElems, borderStyle, borderWidth, borderColor, gradColor);

        targetElems.querySelector(`svg`).innerHTML = newSVG;
      }
    }
  };

  return { getNode, replaceDuplicateIdFromSVG };
};

export default useReplaceDuplicateShapeId;
