import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import cx from "classnames";
import _ from "lodash";

import global from "../../scss/dhp.scss";
import EditorLoader from "./EditorLoader";
import EditorWrapper from "./EditorWrapper";
import EditorSidebar from "./EditorSidebar";
import RightContextMenubar from "./RightContextMenubar";

import { AppContext } from "../../contexts";
import { DOCUMENT_NOT_FOUND } from "../../constants/document";
import { TYPE_INFOGRAPHIC, TYPE_PROPOSAL } from "../../constants/editor";
import { calculateNewZoomValue, getSvgContentFromUrl, loadAllDBFonts, loadAllMaps, setResponsiveSrcToAllWidgets } from "../../_helpers/utils";

import { getResourceEntries } from "../../store/actions/resourcesActions";
import { fetchDocumentDetails } from "../../store/actions/documentActions";
import { fetchBrands, fetchBrandDetils } from "../../store/actions/companyActions";
import { fetchTemplateTypes, fetchTemplatePageSizes } from "../../store/actions/templateActions";
import { fetchCountryList, fetchRegionList, fetchWidgetList } from "../../store/actions/editorActions";

import useCustomFonts from "../../hooks/useCustomFonts";
import useStateHistory from "../../hooks/useStateHistory";
import useDeletedCustomFonts from "../../hooks/useDeletedCustomFonts";

let style = Object.assign({}, global);

export const EditorContext = React.createContext({});

const EditorLayout = () => {
  const editorMetadata = {
    pageController: {
      style: {
        top: 0,
      },
    },
    activeOutSideCanvasArea: false,
    activePageId: false,
    activePageIdx: 0,
    activeBlockId: false,
    activeBlockIdx: 0,
    activeWidgetId: false,
    activeWidgetType: false,
    activeWidgetIdx: 0,
    pageCount: 1,
    blockCount: 1,
  };

  const editorPageMargin = {
    enabled: false,
    value: "",
    style: {
      zIndex: 1000,
      position: "absolute",
      pointerEvents: "none",
    },
  };
  const editorDimension = {
    dpi: 72,
    size: "a4",
    width: 0,
    height: 0,
    zoom: 100,
    prevZoom: 100,
    orientation: "portrait",
    unit: "px",
    label: "A4",
    displayUnit: "px",
  };

  const editorRightContextMenu = {
    enable: false,
    style: {},
  };

  const editorGrid = {
    enabled: false,
    size: 15,
    color: "black",
    strokeWidth: 0.5,
  };

  const [templateGroupName, setTemplateGroupName] = useState("");
  const [documentType, setDocumentType] = useState("");
  const [metadata, setmetadata] = useState(editorMetadata);
  const [pageMargin, setPageMargin] = useState(editorPageMargin);
  const [dimension, setdimension] = useState(editorDimension);
  const [isProcessDone, setIsProcessDone] = useState(false);
  const [rightContextMenu, setRightContextMenu] = useState(editorRightContextMenu);
  const [transitionAppliedAllPages, setTransitionAppliedAllPages] = useState(false);
  const [grid, setgrid] = useState(editorGrid);
  const [snapGrid, setSnapGrid] = useState([]);
  const [isUploadDone, setisUploadDone] = useState(true);
  const [isDocumentReady, setIsDocumentReady] = useState(false);
  const [textFontSize, setTextFontSize] = useState("");
  const [isTimeLineViewOpen, setIsTimeLineViewOpen] = useState(false);
  const [uploadState, setUploadState] = useState({
    isOutSideAssetUpload: false,
    uploadSchemeType: null,
  }); //Redirects outside asset upload to sidebar & & update SchemeType
  const [uploadAssetError, setUploadAssetError] = useState({ status: false, message: "" });

  // ToDo -- this detail will be fatched by previous page.. once privious form page done the code will be deleted and this value will pass by props
  const dispatch = useDispatch();
  const { id } = useParams();
  const redirectionHistory = useHistory();

  const fonts = useSelector(state => state?.resources?.fonts);
  const documentDetails = useSelector(state => state?.document?.documentDetails?.data);
  const documentErrorMsg = useSelector(state => state?.document?.error?.message);
  const availableTemplateGroups = useSelector(state => state?.template?.templateGroups);
  const availableTemplatePageSizes = useSelector(state => state?.template?.templatePageSizes);
  const isCompSuperAdmin = useSelector(state => state.auth?.user?.company?.superadmin_company);
  const userInfo = useSelector(state => state?.auth?.user);
  const brandInfo = useSelector(state => state.company?.brandInfo);
  const regions = useSelector(state => state?.editor?.availableRegionLists?.regions);

  let { setBrandInfo } = useContext(AppContext);

  useCustomFonts(); // use active custom fonts
  useDeletedCustomFonts(); // use deleted custom fonts

  // Redirect to Templates page if document not found
  useEffect(() => {
    if (documentErrorMsg && documentErrorMsg === DOCUMENT_NOT_FOUND && isProcessDone) {
      redirectionHistory.push("/templates");
    }
  }, [documentErrorMsg, isProcessDone]);

  // Load all fonts
  useEffect(() => {
    if (!fonts) dispatch(getResourceEntries("fonts"));
    else loadAllDBFonts(fonts);
  }, [fonts]);

  // pre-Load all region's images
  useEffect(() => {
    if (regions) loadAllMaps(regions);
  }, [regions]);

  //Fetch api for the 1st type insert in the document
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (id) {
          dispatch(showLoading());
          await dispatch(fetchDocumentDetails(id));
          await dispatch(fetchTemplateTypes(isCompSuperAdmin));
          await dispatch(fetchTemplatePageSizes());
          await dispatch(fetchWidgetList(isCompSuperAdmin));
          await dispatch(fetchRegionList()); // preload map modal with Regions thumb and World Map
          await dispatch(fetchCountryList()); // preload map modal with country Map

          // preload World svg
          let worldSvg = await getSvgContentFromUrl("https://assets.dochipo.com/editor/maps/regions/world.svg");
          let worldObj = { svg: worldSvg.outerHTML };
          localStorage.setItem("world-svg", JSON.stringify(worldObj));

          //Fetch BrandList with default Brand Fetch
          const response = await dispatch(fetchBrands());
          const defaultBrandId = sessionStorage.getItem("lastSelectedBrandId") || response?.results?.brands?.[0]?.id;
          if (defaultBrandId) {
            await dispatch(fetchBrandDetils(defaultBrandId));
          }
          setIsProcessDone(true);
          dispatch(hideLoading());
        }
      } catch {
        dispatch(hideLoading());
      }
    };

    fetchData();

    let mainElem = document.getElementsByTagName("main")[0];

    // add "editor-body" class and id when insert to the editor and remove 'hide-secondAside' class to prevent collapse sidebar when 1st insert in editor
    mainElem?.classList.add("editor-body");
    mainElem?.setAttribute("id", "editorBody");
    mainElem?.classList.remove("hide-secondAside");
    document.getElementsByTagName("body")[0]?.classList.add("open-editor");

    // reset widget and collage events to false on load
    localStorage.setItem("widget.event.isActive", "false");
    localStorage.setItem("collage.event.isActive", "false");
    localStorage.removeItem("documents");

    // get timeline status from session storage to hold the prafarence when refresh the page
    let timeline = sessionStorage.getItem("timeline-status");
    setIsTimeLineViewOpen(timeline === "false" || timeline === "null" ? false : timeline);

    return () => {
      sessionStorage.removeItem("timeline-status");
      sessionStorage.removeItem("dataObj");
      //Remove lastSelectedBrandId when leave from Editor
      sessionStorage.removeItem("lastSelectedBrandId");

      //remove pagetop width and icon size width
      window.sessionStorage.removeItem("page_Top_Option_Width");
      window.sessionStorage.removeItem("icon_Size_Width");

      //remove "editor-body" class and id when leave from the editor
      mainElem?.classList.remove("editor-body");
      mainElem?.removeAttribute("id");
      document.getElementsByTagName("body")[0]?.classList.remove("open-editor");

      // unfreeze editor on leaving (in-case freezed during AI writer action from toolbar)
      document.querySelectorAll(".pe-none").forEach(e => e.classList.remove("pe-none"));

      // reset widget and collage events to false on leave
      localStorage.setItem("widget.event.isActive", "false");
      localStorage.setItem("collage.event.isActive", "false");
    };
  }, []);

  // for editor loading time panel-toggle hide
  useEffect(() => {
    let mainElem = document.querySelector(".canvas-panel-area .panel-toggle");

    if (!parseInt(dimension?.width) || !parseInt(dimension?.height)) mainElem?.classList.add("d-none");
    else mainElem?.classList.remove("d-none");
  }, [dimension?.width, dimension?.height]);

  useEffect(() => {
    if (
      documentDetails &&
      availableTemplateGroups &&
      availableTemplatePageSizes &&
      isProcessDone &&
      documentDetails?.permission &&
      !isDocumentReady
    ) {
      if (documentDetails?.permission.toLowerCase() === "ro") redirectionHistory.push("/templates");
      else {
        setDocumentType(documentDetails?.template_info?.type?.toLowerCase());
        let templateDefaultSize;
        let templateGroupId = documentDetails.template_info.group_id;
        let templateTypeId = documentDetails.template_info.type_id;

        // set landscape orientation for certificate - set up for granular structure
        let specialCase = ["certificate", "brochure"].includes(documentDetails?.template_info?.type?.toLowerCase());
        const isBlankDocument = !documentDetails?.version?.rawdata;
        const documentDimension = documentDetails?.version?.rawdata?.metadata?.dimension;

        availableTemplateGroups.template_groups.map(availableTemplateGroup => {
          if (availableTemplateGroup.id === templateGroupId) {
            setTemplateGroupName(availableTemplateGroup.slug);

            availableTemplateGroup.type.map(templateType => {
              if (templateType.id === templateTypeId) {
                templateDefaultSize = templateType.default_size;
                if (templateDefaultSize === "custom") {
                  setdimension({
                    ...dimension,
                    width: !isBlankDocument
                      ? documentDimension?.displayUnit === "px"
                        ? parseInt(documentDimension.width)
                        : parseFloat(documentDimension.width)
                      : templateType.custom_width,
                    height: !isBlankDocument
                      ? documentDimension?.displayUnit === "px"
                        ? parseInt(documentDimension.height)
                        : parseFloat(documentDimension.height)
                      : templateType.custom_height,
                    label: !isBlankDocument ? documentDimension.label : templateDefaultSize,
                    size: !isBlankDocument ? documentDimension.size : templateDefaultSize,
                    displayUnit: !isBlankDocument
                      ? documentDimension?.displayUnit ?? documentDimension.unit
                      : dimension.unit,
                    zoom: !isBlankDocument
                      ? dimension.zoom
                      : calculateNewZoomValue(
                          templateType.custom_width,
                          templateType.custom_height,
                          false,
                          userInfo?.video_enabled
                        ),
                  });
                }
              }
            });
          }
        });

        availableTemplatePageSizes.map(template_page_size => {
          template_page_size.page.map(page => {
            if (page.value === templateDefaultSize && templateDefaultSize !== "custom") {
              setdimension({
                ...dimension,
                width: !isBlankDocument
                  ? documentDimension?.displayUnit === "px"
                    ? parseInt(documentDimension.width)
                    : parseFloat(documentDimension.width)
                  : parseInt(specialCase ? page.height : page.width),
                height: !isBlankDocument
                  ? documentDimension?.displayUnit === "px"
                    ? parseInt(documentDimension.height)
                    : parseFloat(documentDimension.height)
                  : parseInt(specialCase ? page.width : page.height),
                label: !isBlankDocument ? documentDimension.label : page?.label,
                size: !isBlankDocument ? documentDimension.size : page?.value,
                orientation: specialCase ? "landscape" : "portrait",
                displayUnit: !isBlankDocument
                  ? documentDimension?.displayUnit ?? documentDimension.unit
                  : dimension.unit,
                zoom: !isBlankDocument
                  ? dimension.zoom
                  : calculateNewZoomValue(
                      parseInt(specialCase ? page.height : page.width),
                      parseInt(specialCase ? page.width : page.height),
                      false,
                      userInfo?.video_enabled
                    ),
              });
            }
          });
        });

        if (!templateDefaultSize && documentDimension) setdimension({ ...dimension, ...documentDimension });
      }
    }
  }, [documentDetails, availableTemplateGroups, availableTemplatePageSizes, isProcessDone, isDocumentReady]);
  // ---------- END section to delete -----------//

  const toggle = () => {
    document.getElementById("editorBody").classList.toggle("hide-secondAside");

    if (![TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType)) {
      setTimeout(() => {
        let newZoomVal = calculateNewZoomValue(
          dimension.width,
          dimension.height,
          isTimeLineViewOpen,
          userInfo?.video_enabled
        );
        //Fit document to canvas
        if (parseFloat(dimension.zoom) !== parseFloat(newZoomVal)) {
          setdimension({
            ...dimension,
            zoom: newZoomVal,
          });
        }
      }, 500);
    }
  };

  const [contextData, setContextData, { history, pointer, back, forward }] = useStateHistory(
    { pageNodes: [], blockNodes: [], widgets: [], backgroundColors: [], backgroundImages: [], audios: [] },
    { docType: documentType }
  );

  const updateContext = (
    key,
    value,
    optionalPageData = null,
    optionalBlockData,
    optionalBgcolorData,
    optionalBgImageData,
    optionalActiveBlockIndex,
    currentDimension,
    resizedStatus,
    currentDocumentType,
    optionalAudioData
  ) => {
    switch (key) {
      case "page":
        break;

      case "block":
        setContextData(() => {
          return {
            type: "blockNodes",
            value: value,
            optionalPageData: optionalPageData,
            optionalActiveBlockIndex: optionalActiveBlockIndex,
            currentDimension: currentDimension,
            currentDocumentType: currentDocumentType,
          };
        });

        break;

      case "widget":
        setContextData(() => {
          return {
            type: "widgets",
            value: value,
            optionalPageData: optionalPageData,
            optionalBlockData: optionalBlockData,
            optionalBgcolorData: optionalBgcolorData,
            optionalBgImageData: optionalBgImageData,
            optionalActiveBlockIndex: optionalActiveBlockIndex,
            currentDimension: currentDimension,
            resizedStatus: resizedStatus,
            currentDocumentType: currentDocumentType,
            optionalAudioData: optionalAudioData,
          };
        });

        break;

      case "bgcolor":
        setContextData(() => {
          return { type: "backgroundColors", value: value, currentDimension: currentDimension };
        });

        break;

      case "bgimage":
        setContextData(() => {
          return { type: "backgroundImages", value: value, currentDimension: currentDimension };
        });

        break;

      case "audio":
        setContextData(() => {
          return { type: "audios", value: value, currentDimension: currentDimension };
        });

        break;

      default:
        console.warn("key not in acceptable values");
        break;
    }
  };

  // Update brand inforamtion when brandInfo changes
  useEffect(() => {
    if (brandInfo) setBrandInfo(brandInfo);
  }, [brandInfo]);

  // set responsive srcset as per changes in widgets array
  useEffect(() => {
    if (isDocumentReady) setResponsiveSrcToAllWidgets({ widgets: contextData.widgets, zoom: dimension.zoom });
  }, [contextData.widgets, isDocumentReady]);

  return (
    <EditorContext.Provider
      value={{
        templateGroupName,
        updateTemplateGroupName: data => {
          if (!_.isEqual(templateGroupName, data)) setTemplateGroupName(data);
        },
        documentType,
        updateDocumentType: data => {
          if (!_.isEqual(documentType, data)) setDocumentType(data);
        },
        ...contextData,
        updatePageNodes: data => {
          updateContext("page", data);
        },
        updateBlockNodes: (blockData, pageData = null, activeBlockIdx) => {
          updateContext("block", blockData, pageData, false, false, false, activeBlockIdx, dimension);
        },
        updateWidgets: (
          widgetData,
          pageData,
          blockData,
          bgcolorData,
          bgImageData,
          activeBlockIdx,
          curdimension,
          resizedStatus,
          currentDocumentType,
          audioData
        ) => {
          let optionalDimension = curdimension ? curdimension : dimension;
          let docType = currentDocumentType ? currentDocumentType : documentType;
          updateContext(
            "widget",
            widgetData,
            pageData,
            blockData,
            bgcolorData,
            bgImageData,
            activeBlockIdx,
            optionalDimension,
            resizedStatus,
            docType,
            audioData
          );
        },
        updateBackgroundColors: data => {
          updateContext("bgcolor", data, false, false, false, false, false, dimension);
        },
        updateBackgroundImages: data => {
          updateContext("bgimage", data, false, false, false, false, false, dimension);
        },
        setAudios: audioData => {
          updateContext("audio", audioData, false, false, false, false, false, dimension);
        },
        metadata,
        updateMetadata: data => {
          if (!_.isEqual(metadata, data)) setmetadata(data);
        },
        pageMargin,
        updatePageMargin: data => {
          if (!_.isEqual(pageMargin, data)) setPageMargin(data);
        },
        grid,
        updateGrid: data => {
          if (!_.isEqual(grid, data)) setgrid(data);
        },
        dimension,
        updateDimension: data => {
          if (!_.isEqual(dimension, data)) setdimension(data);
        },
        rightContextMenu,
        updateRightContextMenu: data => {
          if (!_.isEqual(rightContextMenu, data)) setRightContextMenu(data);
        },
        transitionAppliedAllPages,
        updateTransitionAppliedAllPages: data => {
          if (transitionAppliedAllPages !== data) setTransitionAppliedAllPages(data);
        },
        snapGrid,
        updateSnapGrid: data => {
          if (!_.isEqual(snapGrid, data)) setSnapGrid(data);
        },
        undo: back,
        redo: forward,
        HistoryList: history,
        currentPointerPos: pointer,
        isUploadDone,
        setisUploadDone: data => setisUploadDone(data),
        isDocumentReady,
        setIsDocumentReady,
        textFontSize,
        setTextFontSize,
        isTimeLineViewOpen,
        setIsTimeLineViewOpen,
        uploadState,
        setUploadState, // Redirects outside asset upload to sidebar & & update SchemeType
        uploadAssetError,
        setUploadAssetError,
      }}>
      <>
        {parseInt(dimension?.width) > 0 && parseInt(dimension.height) > 0 && (
          <>
            <EditorSidebar />

            <EditorWrapper toggle={toggle} />

            {/* Display when drag widget from left pane */}
            <div
              id="drag-path-display"
              className={cx(style["drag-path-display"], style["d-none"])}
              onContextMenu={e => e.preventDefault()}></div>
          </>
        )}

        {(!parseInt(dimension?.width) || !parseInt(dimension?.height)) && <EditorLoader />}
      </>
    </EditorContext.Provider>
  );
};

export default EditorLayout;
