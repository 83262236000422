import React, { useEffect } from "react";
import { Modal as RModal } from "reactstrap";
import PropTypes from "prop-types";
import global from "../../scss/dhp.scss";

let style = Object.assign({}, global);

const Modal = ({
  showModal,
  setShowModal,
  backdrop,
  component,
  modalClassName,
  className,
  centered,
  size,
  ...props
}) => {
  const Component = component;
  const backdropData = backdrop ? "static" : true;
  document.body.style.overflow = showModal ? "hidden" : "";

  useEffect(() => {
    return () => {
      // force clear overflow property at unmount
      document.body.style.overflow = "";
    };
  }, []);

  // Below code to check is tour tooltip open or not if open then decrease zIndex
  useEffect(() => {
    if (!showModal) return;
    const tooltipTourDiv = document.querySelector(".tour .tooltip.show");
    let originalZIndex = null;
    if (tooltipTourDiv) {
      const computedStyle = window?.getComputedStyle(tooltipTourDiv);
      originalZIndex = computedStyle.zIndex;

      // Update the z-index to 1040
      tooltipTourDiv.style.zIndex = "1040";
    }

    // Cleanup function to restore the original z-index on unmount
    return () => {
      if (tooltipTourDiv && originalZIndex !== null) {
        tooltipTourDiv.style.zIndex = originalZIndex;
      }
    };
  }, [showModal]);

  return (
    <RModal
      size={size}
      centered={centered ?? true}
      isOpen={showModal}
      backdrop={backdropData}
      modalClassName={modalClassName}
      contentClassName={style["rounded-lg"]}
      className={className}
      toggle={() => setShowModal(!showModal)}>
      <Component {...{ showModal, setShowModal, ...props }} />
    </RModal>
  );
};

Modal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func,
  backdrop: PropTypes.string,
  component: PropTypes.func.isRequired,
  modalClassName: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.string,
  centered: PropTypes.bool,
};

export default Modal;
