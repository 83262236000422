import React, { useState, useEffect, useRef, useContext } from "react";
import { useDispatch } from "react-redux";
import cx from "classnames";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  TabContent,
  TabPane,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Input as RInput,
  Form,
  UncontrolledDropdown,
  Tooltip,
} from "reactstrap";

import { fetchStockPhoto } from "../../../store/actions/editorActions";
import {
  stopAllResourceBuffering,
  getImgDimensionFromUrl,
  debounceTrailing as debounce,
  preventFormSubmitOnEnter as preventSubmit,
} from "../../../_helpers/utils";
import { RMasonry as Masonry } from "../../ui/masonry";
import { MasonryLoader } from "../../ui/loader/masonryLoader";
import { DotLoader } from "../../ui/loader/dotLoader";
import AssetsLoader from "../../ui/loader/assetsLoader";
import { Icon } from "../../ui/icon";
import { Input } from "../../ui/input";
import global from "../../../scss/dhp.scss";
import NoPreview from "../../../assets/images/no-preview.jpg";
import crown from "../../../assets/images/ui-crown.svg";

import AssetName from "./Common/AssetName";
import AssetSchemeTypeNav from "./Common/AssetSchemeTypeNav";
import AssetSearch from "./Common/AssetSearch";
import AssetCategory from "./Common/AssetCategory";
import { useContextualUpgrade } from "../../../hooks/useContextualUpgrade";
import { useCheckCompanyPlanInfo } from "../../../hooks/useCheckCompanyPlanInfo";
import { COMPANY_SUPERADMIN } from "../../../constants/company";

import useElementInnerHtml from "../../../hooks/useElementInnerHtml";
import useAddWidget from "../../../hooks/useAddWidget";
import { widgetConfig } from "../editor_config";
import { STOCK, STOCK_SERVICE_PROVIDERS as STOCK_SPS, UTM_PARAMS, AI } from "../../../constants/editor";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import useDragDropWidget from "../../../hooks/useDragDropWidget";
import useAlignment from "../../../hooks/useAlignment";
import useTextFocusOut from "../../../hooks/useTextFocusOut";
import StockSPs from "./Common/StockSPs";
import {
  createTextToImageCollection,
  fetchTextToImageStyle,
  fetchTextToImageCollectionDetail,
  fetchTextToImageList,
  updateTextToImageCollection,
  deleteTextToImageCollection,
  createTextToImageEtaCollection,
  updateTextToImageEtaCollection,
} from "../../../store/actions/aiActions";
import Modal from "../../ui/modal";
import DeleteElemModal from "../Modals/DeleteElemModal";
import { DELETE_TEXT_TO_IMAGE_COLLECTION_SUCCESS } from "../../../store/actions/actionTypes";
import CommonLineLoader from "../../../components/ui/loader/commonLineLoader";

let style = Object.assign({}, global);

const UploadAsset = props => {
  return (
    <div
      className={cx(style["asset-item"], style["asset-item-icon"])}
      onClick={() => props.handleWidgetAction("upload", "Uploads", "uploads", 1)}>
      <div className={style["item-holder"]}>
        <Icon icon="ui-plus" additionalclass="custom-tooltip">
          <div className={style["custom-tooltip-content"]}>Add Your Picture</div>
        </Icon>
      </div>
    </div>
  );
};
//Props type validation
UploadAsset.propTypes = {
  handleWidgetAction: PropTypes.func.isRequired,
};

export const StockPhotoFilters = props => {
  const orientations = ["Horizontal", "Vertical"];
  const colors = [
    "blue",
    "black",
    "gray",
    "pink",
    "white",
    "green",
    "red",
    "yellow",
    "orange",
    "turquoise",
    "purple",
    "brown",
  ];

  const [payload, setPayload] = useState({});
  const [showClear, setShowClear] = useState(false);
  const [disableForm, setDisableForm] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [filterCount, setFilterCount] = useState(0);

  const toggle = () => setDropdownOpen(prevState => !prevState);

  const updatePayload = (type, data) => {
    setPayload({ ...payload, [type]: data });
    setShowClear(true);
    setDisableForm(false);
  };

  const clearFilter = () => {
    setPayload({ orientation: false, color: false });
    setShowClear(false);
    ["stockPhotoFilterOrientation", "stockPhotoFilterColor"].forEach(radio => {
      if (document.querySelector(`input[name="${radio}"]:checked`)) {
        document.querySelector(`input[name="${radio}"]:checked`).checked = false;
      }
    });
  };

  const applyFilter = () => {
    props.setFilters(payload);
    props.setPage(1);
    setDisableForm(true);
    toggle();
  };

  useEffect(() => {
    setDisableForm(props.filters?.orientation === payload.orientation && props.filters?.color === payload.color);
  }, [payload]);

  useEffect(() => {
    setFilterCount(Object.keys(props.filters).filter(f => props.filters[f])?.length);
  }, [props.filters]);

  useEffect(() => {
    setPayload(props.filters);
    setShowClear(Object.keys(props.filters).some(f => props.filters[f]));
  }, [dropdownOpen]);

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} className={cx(style["more-action"])}>
      <DropdownToggle>{filterCount > 0 && <span className={style["filter-count"]}>{filterCount}</span>}</DropdownToggle>
      <DropdownMenu className={cx(style["dropdown-menu-right"], style["shadow-sm"], style["border-0"], style["p-3"])}>
        <div className={cx(style["fw-6"], style["color-black"])}>Images from</div>
        <div className={cx(style["d-flex"], style["font-15"])}>
          <StockSPs fileType="photo" excludeList={["pixabay"]} />
        </div>
        <>
          <DropdownItem divider />
          <div className={cx(style["form-group"], style["mr-0"])}>
            <div
              className={cx(
                style["d-flex"],
                style["justify-content-between"],
                style["align-items-center"],
                style["mb-2"]
              )}>
              <label className={style["m-0"]}>Orientation</label>
              {showClear && (
                <div className={cx(style["clear"], style["custom-tooltip"])} onClick={() => clearFilter()}>
                  <Icon icon="ui-reset" />
                  <div className={cx(style["custom-tooltip-content"], style["bottom"])}>Clear</div>
                </div>
              )}
            </div>
            {orientations.map((orientation, i) => (
              <div key={i} className={cx(style["custom-control"], style["custom-radio"], style["mb-2"])}>
                <RInput
                  type="radio"
                  id={`stockPhotoFilterOrientation${i}`}
                  name="stockPhotoFilterOrientation"
                  className={style["custom-control-input"]}
                  defaultChecked={orientation === props.filters.orientation}
                  value={orientation}
                  onChange={() => updatePayload("orientation", orientation)}
                />
                <label className={style["custom-control-label"]} htmlFor={`stockPhotoFilterOrientation${i}`}>
                  {orientation}
                </label>
              </div>
            ))}
          </div>

          <div className={cx(style["form-group"], style["mb-3"], style["mr-0"])}>
            <label>Color</label>
            <div className={cx(style["photo-colors"], style["color-radio"])}>
              {colors.map((color, i) => (
                <div
                  key={i}
                  className={cx(
                    style["custom-control"],
                    style["custom-radio"],
                    style["custom-control-inline"],
                    style[`color-${color}`]
                  )}>
                  <RInput
                    type="radio"
                    id={`stockPhotoFilterColor${i}`}
                    name="stockPhotoFilterColor"
                    className={style["custom-control-input"]}
                    defaultChecked={color === props.filters.color}
                    value={color}
                    onChange={() => updatePayload("color", color)}
                  />
                  <label className={style["custom-control-label"]} htmlFor={`stockPhotoFilterColor${i}`}></label>
                </div>
              ))}
            </div>
          </div>
          <Button
            type="submit"
            color="primary"
            className={style["btn-block"]}
            cssModule={style}
            disabled={disableForm}
            onClick={() => applyFilter()}>
            Apply Filters
          </Button>
        </>
      </DropdownMenu>
    </Dropdown>
  );
};
//Props type validation
StockPhotoFilters.propTypes = {
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
};

const StockPhotoAsset = props => {
  const imgRef = useRef();
  const {
    image_url: hdPhoto,
    preview_url: sdPhoto,
    thumb_url: thumbPhoto,
    source,
    id: photoId,
    name: contributorName,
    profile_url: contributorProfile,
  } = props.photo;

  const srcSet = {
    s1: sdPhoto,
    s2: thumbPhoto,
    s3: hdPhoto,
  };

  const { metadata, dimension } = useContext(EditorContext);
  const { start: initDragDropWidget } = useDragDropWidget();

  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [assetInnerContent, setAssetInnerContent] = useState();
  const [widgetWidth, setWidgetWidth] = useState();
  const [widgetHeight, setWidgetHeight] = useState();
  const [dataParam, setDataParam] = useState();
  const [addWidgetClicked, setAddWidgetClicked] = useState(false);
  const [hotlinkData, setHotlinkData] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  const imageEventHandler = (e, type) => {
    setIsImageLoaded(true);
    if (type === "error") e.target.src = NoPreview;
  };

  const generateHotlinkData = () => {
    const profileUrl = (contributorProfile ?? thumbPhoto)?.replace("https:", "") ?? STOCK_SPS[source].url.photo;

    setHotlinkData({
      photoId,
      contributorName,
      contributorProfile: `${profileUrl}${UTM_PARAMS}`,
    });
  };

  const setTextFocusOut = useTextFocusOut();
  const { postion: dropPosition } = useAlignment(addWidgetClicked, widgetWidth, widgetHeight, "middle-center");
  const getNewWidgetObject = useAddWidget(
    addWidgetClicked,
    props.assetType,
    dataParam,
    dropPosition,
    widgetWidth,
    widgetHeight,
    srcSet
  );
  useElementInnerHtml(
    addWidgetClicked,
    setAddWidgetClicked,
    props.assetType,
    assetInnerContent,
    dataParam,
    getNewWidgetObject
  );

  const insertElem = async () => {
    let paramObj = {
      "data-source": STOCK,
      "data-origin": source,
      "data-download": hotlinkData.photoId,
    };

    const { width: imgWidth, height: imgHeight } = await getImgDimensionFromUrl(sdPhoto);
    const imgAspectRatio = imgWidth / imgHeight;
    let { width: activeBlockWidth } = document.getElementById(`${metadata.activeBlockId}`)?.getBoundingClientRect();
    activeBlockWidth = (activeBlockWidth * 100) / parseFloat(dimension.zoom);
    const width = imgWidth <= activeBlockWidth ? imgWidth : activeBlockWidth;
    const height = imgWidth <= activeBlockWidth ? imgHeight : width / imgAspectRatio;

    setAssetInnerContent(sdPhoto);
    setWidgetWidth(width);
    setWidgetHeight(height);
    setDataParam({ ...widgetConfig[props.assetType].dataAttr, ...paramObj });
  };

  const widgetAddOnClickHandler = e => {
    const isLeftClick = e.button === 0;
    const isRestrictedArea = e.target.closest(".stock-photo-hotlink");

    // NOTE:::: Only when left mouse button is pressed i.e. MouseEvent.button = 0 AND not clicked on either of the restricted areas
    if (isLeftClick && !isRestrictedArea) {
      if (document.querySelector(".dhp-content-editable-true-text")) setTextFocusOut(true);
      if (isImageLoaded) insertElem();
    }
  };

  const widgetAddOnDNDHandler = e => {
    const isLeftClick = e.button === 0;

    // NOTE:::: Only when left mouse button is pressed i.e. MouseEvent.button = 0
    if (isLeftClick) {
      initDragDropWidget(
        {
          asset: props.assetType,
          value: sdPhoto,
          source: STOCK,
          origin: source,
          download: hotlinkData.photoId,
          srcSet,
        },
        e
      );
    }
  };

  const scrollLeftPane = () => {
    setTooltipOpen(false);
  };

  // trigger widget INSERT
  useEffect(() => {
    if ((assetInnerContent, widgetWidth && widgetHeight && dataParam)) {
      setAddWidgetClicked(true);
    }
  }, [assetInnerContent, widgetWidth, widgetHeight, dataParam]);

  useEffect(() => {
    generateHotlinkData();
    if (imgRef.current.complete) setIsImageLoaded(true);
  }, []);

  useEffect(() => {
    document.querySelector(".assets-wrapper").addEventListener("scroll", scrollLeftPane);

    return () => {
      document.querySelector(".assets-wrapper")?.removeEventListener("scroll", scrollLeftPane);
    };
  }, []);

  return (
    <>
      {!isImageLoaded && <MasonryLoader module="editor_assets" type="item" />}

      <div
        id={`asset-image-${props.photo.id}`}
        className={cx(style["asset-item"], {
          [style["d-none"]]: !isImageLoaded,
        })}
        onClick={e => widgetAddOnClickHandler(e)}
        onMouseDown={e => widgetAddOnDNDHandler(e)}>
        <div className={style["item-holder"]}>
          <img
            ref={imgRef}
            className={style["img-fluid"]}
            src={sdPhoto ?? NoPreview}
            alt="preview thumbnail"
            onLoad={e => imageEventHandler(e, "load")}
            onError={e => imageEventHandler(e, "error")}
          />
          {hotlinkData?.contributorProfile && (
            <Link
              className={cx(style["hotlink"], style["text-truncate"], "stock-photo-hotlink")}
              target="_blank"
              to={hotlinkData?.contributorProfile}>
              {hotlinkData?.contributorName}
            </Link>
          )}
        </div>
      </div>

      {isImageLoaded && (
        <Tooltip
          className="asset-hover-tooltip"
          placement="right"
          isOpen={tooltipOpen}
          target={`asset-image-${props.photo.id}`}
          toggle={toggle}
          boundariesElement={document.getElementById("app")}>
          <div className="image-preview preview-picture">
            <img src={sdPhoto} alt="" className={style["img-fluid"]} />
          </div>
        </Tooltip>
      )}
    </>
  );
};
//Props type validation
StockPhotoAsset.propTypes = {
  photo: PropTypes.object.isRequired,
  assetType: PropTypes.string.isRequired,
};

const StockPhotoList = props => {
  const dispatch = useDispatch();
  const loadMoreRef = useRef();
  const stockPhotoListRef = useRef();

  const [availablePhotos, setAvailablePhotos] = useState([]);
  const [hasMorePhotos, setHasMorePhotos] = useState(true);
  const [loading, setLoading] = useState(true);

  const getStockPhotos = () => {
    const payload = {
      limit: 25,
      page: props.page,
      keyword: props.keyword,
    };
    if (props.filters.orientation) payload.orientation = props.filters.orientation;
    if (props.filters.color) payload.color = props.filters.color;
    if (props.page === 1 && stockPhotoListRef.current) stockPhotoListRef.current.scrollTop = 0;
    setLoading(true);
    dispatch(fetchStockPhoto(payload))
      .then(res => {
        if (res.status === 200) {
          let total = res.data.data.summary.total ?? 0;
          let lastPage = Math.floor(total % payload.limit === 0 ? total / payload.limit : total / payload.limit + 1);
          setLoading(false);
          setHasMorePhotos(payload.page < lastPage);
          setAvailablePhotos(payload.page > 1 ? [...availablePhotos, ...res.data.data.photos] : res.data.data.photos);
        }
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    if (availablePhotos) props.setIsAssetFetched(true);
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting && hasMorePhotos) props.setPage(props.page + 1);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0,
      }
    );
    if (loadMoreRef.current) observer.observe(loadMoreRef.current);

    return () => {
      if (loadMoreRef.current) observer.unobserve(loadMoreRef.current);
    };
  }, [availablePhotos]);

  useEffect(() => {
    getStockPhotos();
    if (props.page === 1) {
      props.setIsAssetFetched(false);
      stopAllResourceBuffering(stockPhotoListRef.current, "img");
    }
  }, [props.keyword, props.page, props.filters]);

  useEffect(() => {
    return () => stopAllResourceBuffering(stockPhotoListRef.current, "img");
  }, []);

  return (
    <div
      className={cx(style["customScroll"], style["scroll-Y"], style["assets-wrapper"], style["mt-n2"])}
      ref={stockPhotoListRef}
      onContextMenu={e => e.preventDefault()}>
      <div className={cx(style["StockPhotos"], style["p-0"])}>
        {loading && props.page === 1 && <MasonryLoader module="editor_assets" type="masonry" count={25} />}

        <Masonry module="editor_assets">
          {availablePhotos?.length > 0 && <UploadAsset handleWidgetAction={props.handleWidgetAction} />}
          {props.isAssetFetched &&
            availablePhotos?.map((photo, i) => <StockPhotoAsset key={i} photo={photo} assetType={props.assetType} />)}
        </Masonry>

        {!loading && <div ref={loadMoreRef} style={{ height: "20px" }}></div>}

        {loading && props.page > 1 && <DotLoader />}

        {!loading && availablePhotos?.length === 0 && (
          <div className={cx(style["col"], style["p-0"])}>
            <div className={cx(style["alert"], style["alert-block"], style["alert-danger"])}>
              No matching {props.assetName.toLowerCase()} found
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
//Props type validation
StockPhotoList.propTypes = {
  assetType: PropTypes.string.isRequired,
  handleWidgetAction: PropTypes.func.isRequired,
  keyword: PropTypes.string.isRequired,
  filters: PropTypes.object.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  isAssetFetched: PropTypes.bool.isRequired,
  setIsAssetFetched: PropTypes.func.isRequired,
  assetName: PropTypes.string.isRequired,
};

const StockPhoto = props => {
  const categoryOptions = [
    "Animals",
    "Backgrounds",
    "Beauty and Fashion",
    "Business and Finance",
    "Food and Drink",
    "Music",
    "Nature and Landscapes",
    "People",
    "Science and Technology",
    "Sports",
    "Travel and Vacation",
  ];
  const [keyword, setKeyword] = useState("");
  const [page, setPage] = useState(1);
  const [category, setCategory] = useState("");
  const [isAssetFetched, setIsAssetFetched] = useState(false);

  const [filters, setFilters] = useState({
    orientation: false,
    color: false,
  });

  return (
    <TabPane tabId="1" className={style["active"]}>
      <AssetSearch
        assetName={props.assetName}
        schemeType={props.schemeType}
        setKeyword={setKeyword}
        setPageNumber={setPage}
        setCategory={setCategory}
        setFilters={setFilters}
        filters={filters}
      />
      <AssetCategory
        category={category}
        setCategory={setCategory}
        setKeyword={setKeyword}
        setPageNumber={setPage}
        staticCategory={categoryOptions}
        assetType={props.assetType}
      />
      <StockPhotoList
        assetType={props.assetType}
        handleWidgetAction={props.handleWidgetAction}
        keyword={keyword}
        filters={filters}
        page={page}
        setPage={setPage}
        isAssetFetched={isAssetFetched}
        setIsAssetFetched={setIsAssetFetched}
        assetName={props.assetName}
      />
    </TabPane>
  );
};
//Props type validation
StockPhoto.propTypes = {
  assetName: PropTypes.string.isRequired,
  assetType: PropTypes.string.isRequired,
  schemeType: PropTypes.string.isRequired,
  handleWidgetAction: PropTypes.func.isRequired,
};

const AIImageGenerator = ({ collectionId, setCollectionId, assetType, sectionName, setSectionName }) => {
  let timeoutId;
  const CREATE = "CREATE";
  const UPDATE = "UPDATE";
  const POPULATE = "POPULATE";
  const imageAtTime = ["2", "4"];
  const dispatch = useDispatch();

  const errorTemplate = {
    text2img: "You have consumed the maximum number of AI generated images for the current month.",
    default: "The service is currently unavailable. Please try again later.",
  };

  const [collection, setCollection] = useState();
  const [updatedCollection, setUpdatedCollection] = useState();
  const [etaCollection, setEtaCollection] = useState();
  const [etaUpdatedCollection, setEtaUpdatedCollection] = useState();
  const [collectionDetail, setCollectionDetail] = useState();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchKeyWord, setSearchKeyWord] = useState("");
  const [styleAI, setStyleAI] = useState();
  const [typeCatMap, setTypeCatMap] = useState({});
  const [formFields, setFormFields] = useState({
    describe: "",
    styleType: "None",
    defaultImageAtTime: "2",
  });
  const [availablePhotos, setAvailablePhotos] = useState([]);
  const [charCount, setCharCount] = useState(0);
  const [etaInSec, setEtaInSec] = useState(0);
  const [errorMessage, setErrorMessage] = useState();
  const [inProgress, setInProgress] = useState(false);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [imageStyle, setImageStyle] = useState();

  const styleAIList = styleAI
    ?.map(availableStyleType => ({
      ...availableStyleType,
      options: availableStyleType.options.filter(option =>
        option.name.toLowerCase().includes(searchKeyWord.toLowerCase())
      ),
    }))
    .filter(availableStyleType => availableStyleType?.options?.length > 0);

  const toggleStyleTypeDropdown = () => {
    setDropdownOpen(prevState => !prevState);

    if (dropdownOpen) {
      setSearchKeyWord("");
      return;
    }

    // set dropdown position on selected item
    setTimeout(() => {
      const currentDropdown = document.querySelectorAll(".dropdown-menu-right.show")[0];
      if (currentDropdown) {
        const selectedEl = currentDropdown.querySelectorAll(".active.dropdown-item")[0];
        if (selectedEl) {
          selectedEl.scrollIntoView({ block: "nearest", inline: "start" });
        }
      }
    }, 15);
  };

  const getErrorMessage = (type, data) => {
    return data === "Rate limit exceeded" ? errorTemplate[type] : errorTemplate.default;
  };

  const generateTypeCatMap = imageStyle => {
    const map = {};
    imageStyle.forEach(type => type.options.forEach(cat => (map[cat.name] = type.name)));
    setTypeCatMap(map);
  };

  const updateFormData = ({ type, value }) => {
    const newFormFields = { ...formFields };
    newFormFields[type] = value;

    if (type === "describe") {
      setCharCount(value.trim().length);
    }
    if (type === "styleType") {
      setSearchKeyWord("");
      if (value === "None") {
        newFormFields.type = undefined;
        newFormFields.category = undefined;
      } else {
        newFormFields.type = typeCatMap[value];
        newFormFields.category = value;
      }
    }
    setFormFields(newFormFields);
  };

  const handleSubmit = e => {
    e.preventDefault();

    const payload = {
      promt: formFields.describe.trim(),
      samples: formFields.defaultImageAtTime,
      type: formFields.type,
      category: formFields.category,
      quality: "HD",
    };

    setInProgress(true);

    const action = collectionId
      ? updateTextToImageCollection(collectionId, payload)
      : createTextToImageCollection(payload);

    dispatch(action)
      .then(res => {
        if (res.status === 200) {
          setInProgress(res.data.data.eta ? true : false);
          collectionId ? setUpdatedCollection(res.data.data) : setCollection(res.data.data);
        }
      })
      .catch(err => {
        setInProgress(false);
        setErrorMessage(getErrorMessage("text2img", err?.response?.data?.message));
      });
  };

  const handleETASubmission = ({ type, payload }) => {
    const bufferTimeInMs = 3500; // for 2nd api call, as tested, unless adding more than 2 sec buffer, results are not coming.
    const bufferTimeInSec = 8.5; // for end users, adding total 8.5 sec buffer and additional 5 sec (5 + 3.5 <ETA buffer> = 8.5) for first api call and second api call response time + image rendering time.
    const ETA = Math.ceil(parseFloat(payload.eta)) * 1000 + bufferTimeInMs;
    setEtaInSec(ETA / 1000 + bufferTimeInSec);

    timeoutId = setTimeout(() => {
      if (type === CREATE) {
        dispatch(createTextToImageEtaCollection(payload)).then(res => {
          if (res.status === 200) {
            setInProgress(false);
            setEtaCollection(res.data.data);
          }
        });
      }
      if (type === UPDATE) {
        dispatch(updateTextToImageEtaCollection(collectionId, payload)).then(res => {
          if (res.status === 200) {
            setInProgress(false);
            setEtaUpdatedCollection(res.data.data);
          }
        });
      }
    }, ETA);
  };

  const generatePhotoArray = ({ newHdPhotos, newSdPhotos }) => {
    return newHdPhotos.map((hdPhoto, i) => ({ hdPhoto, sdPhoto: newSdPhotos[i] ?? hdPhoto }));
  };

  const handleDataFeed = ({ type, data }) => {
    const {
      image: newHdPhotos = [],
      thumb: newSdPhotos = [],
      promt: prompt = "",
      category = "None",
      id = null,
    } = data ?? { image: [], thumb: [], promt: "", category: "None", id: null };

    const newPhotos = generatePhotoArray({ newHdPhotos, newSdPhotos }) ?? [];

    if (prompt !== "") setSectionName({ id: "generator", name: prompt });
    if (type === CREATE) {
      setAvailablePhotos(newPhotos);
      setCollectionId(id);
    }
    if (type === UPDATE) {
      setAvailablePhotos([...newPhotos, ...availablePhotos]);
    }
    if (type === POPULATE) {
      setAvailablePhotos(newPhotos);
      setCharCount(prompt.trim().length);
      setFormFields({
        describe: prompt,
        styleType: category,
        defaultImageAtTime: "2",
      });
    }
  };

  useEffect(() => {
    if (collection?.eta) handleETASubmission({ type: CREATE, payload: collection });
    else if (collection?.image?.length > 0) handleDataFeed({ type: CREATE, data: collection });
  }, [collection]);

  useEffect(() => {
    if (etaCollection?.image?.length > 0) handleDataFeed({ type: CREATE, data: etaCollection });
  }, [etaCollection]);

  useEffect(() => {
    if (updatedCollection?.eta) handleETASubmission({ type: UPDATE, payload: updatedCollection });
    else if (updatedCollection?.image?.length > 0) handleDataFeed({ type: UPDATE, data: updatedCollection });
  }, [updatedCollection]);

  useEffect(() => {
    if (etaUpdatedCollection?.image?.length > 0) handleDataFeed({ type: UPDATE, data: etaUpdatedCollection });
  }, [etaUpdatedCollection]);

  useEffect(() => {
    if (collectionDetail?.image?.length > 0) handleDataFeed({ type: POPULATE, data: collectionDetail });
  }, [collectionDetail]);

  useEffect(() => {
    if (inProgress) setSectionName({ ...sectionName, name: "AI Images", disabled: true });
  }, [inProgress]);

  useEffect(() => {
    if (loadingDetail) setSectionName({ ...sectionName, hidden: true });
  }, [loadingDetail]);

  useEffect(() => {
    if (errorMessage) setSectionName({ ...sectionName, hidden: false, disabled: false });
  }, [errorMessage]);

  useEffect(() => {
    if (imageStyle?.length > 0) {
      generateTypeCatMap(imageStyle);
      setStyleAI([
        {
          name: "",
          options: [{ name: "None", slug: "none" }],
        },
        ...imageStyle,
      ]);
    } else {
      dispatch(fetchTextToImageStyle()).then(res => {
        if (res.status === 200) {
          setImageStyle(res.data.data);
        }
      });
    }
  }, [imageStyle]);

  useEffect(() => {
    if (collectionId) {
      setLoadingDetail(true);
      dispatch(fetchTextToImageCollectionDetail(collectionId))
        .then(res => {
          if (res.status === 200) {
            setCollectionDetail(res.data.data);
          }
        })
        .catch(err => {
          setErrorMessage(getErrorMessage("text2img", err?.response?.data?.message));
        })
        .finally(() => {
          setLoadingDetail(false);
        });
    }

    return () => {
      setCollectionId();
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <>
      {loadingDetail && (
        <div className={style["mr-4"]}>
          <CommonLineLoader width={100} height="24px" loaderClass="pb-3" sourceComponent="editor" />
          <div className={cx(style["skeleton-loader-area"], style["mb-3"])}>
            <span
              className={cx(style["loader-item"], style["w-50"])}
              style={{ height: "110px", marginRight: "1rem" }}></span>
            <span className={cx(style["loader-item"], style["w-50"])} style={{ height: "110px" }}></span>
          </div>
          <CommonLineLoader width={100} height="100px" loaderClass="pb-3" sourceComponent="editor" />
          <CommonLineLoader width={100} height="54px" loaderClass="pb-3" sourceComponent="editor" />
        </div>
      )}

      {!loadingDetail && (
        <>
          {collectionId && !inProgress && (
            <div
              className={cx(
                style["customScroll"],
                style["scroll-Y"],
                style["assets-wrapper"],
                style["mt-n2"],
                style["mb-2"],
                style["display-collection-list"]
              )}>
              <ul className={cx(style["row"], style["row-cols-2"])}>
                {availablePhotos?.map((photo, i) => (
                  <AIPhotoAsset key={i} photo={photo} assetType={assetType} index={i} />
                ))}
              </ul>
            </div>
          )}

          {inProgress && (
            <>
              <div className={cx(style["assets-wrapper"])}>
                <ul className={cx(style["row"], style["row-cols-2"])}>
                  <AssetsLoader count={parseInt(formFields.defaultImageAtTime)} />
                </ul>
              </div>

              <div className={cx(style["text-center"], style["pr-4"])}>
                Generating images for{" "}
                <span
                  className={cx(
                    style["fw-6"],
                    style["line3-truncate"],
                    style["pt-1"]
                  )}>{`${formFields.describe}`}</span>
              </div>

              {etaInSec > 0 && (
                <div
                  className={cx(
                    style["text-center"],
                    style["bg-white"],
                    style["border"],
                    style["rounded"],
                    style["mt-3"],
                    style["mr-4"],
                    style["p-2"]
                  )}>
                  Estimated Time: {etaInSec} Sec
                </div>
              )}
            </>
          )}

          {errorMessage && (
            <div className={cx(style["text-center"], style["pr-4"])}>
              <div className={cx(style["alert"], style["alert-block"], style["alert-danger"])}>{errorMessage}</div>
            </div>
          )}

          {!inProgress && !errorMessage && (
            <div
              className={cx(
                style["generate-query"],
                style["assets-wrapper"],
                style["customScroll"],
                style["scroll-Y"],
                {
                  [style["generate-query-more"]]: collectionId,
                  [style["two-Images"]]: availablePhotos?.length === 2,
                }
              )}>
              <Form className={style["px-1"]} onKeyDown={e => preventSubmit(e)} onSubmit={e => handleSubmit(e)}>
                <div className={style["mb-2"]}>
                  <Input
                    // cssModule={style}
                    type="multiline"
                    label="Describe"
                    required={true}
                    placeholder={"Example: A lion sitting in the middle of a desert on a sunny afternoon"}
                    onChange={e => updateFormData({ type: "describe", value: e.target.value })}
                    multilineClass="m-0"
                    maxLength={250}
                    value={formFields.describe}
                    title=""
                  />
                  <div className={cx(style["d-flex"], style["justify-content-end"])}>{`${charCount}/250`}</div>
                </div>

                <div className={cx(style["line-controls-wrap"], style["flex-column"], style["align-items-baseline"])}>
                  <div className={cx(style["slidelabel"], style["w-100"], style["m-0"], style["fw-6"])}>Style</div>

                  <UncontrolledDropdown
                    className={style["border-style"]}
                    isOpen={dropdownOpen}
                    toggle={toggleStyleTypeDropdown}>
                    <DropdownToggle>
                      {formFields.styleType}
                      <Icon icon="ui-arrow-down" />
                    </DropdownToggle>

                    <DropdownMenu right className={cx(style["shadow-sm"], style["border-0"], style["rounded"])}>
                      <div className={cx(style["search"], style["px-3"], style["pt-2"])}>
                        <Input
                          cssModule={style}
                          returnType="inputGroup"
                          addonType="prepend"
                          type="search"
                          id="text-to-image-style"
                          name="text-to-image-style"
                          onChange={e => setSearchKeyWord(e.target.value.trim())}
                          autoFocus={true}
                        />
                      </div>

                      <div className={cx(style["customScroll"], style["scroll-Y"])}>
                        {styleAIList?.length === 0 && <div className={cx(style["not-found"])}>No results found</div>}

                        {styleAIList?.length > 0 &&
                          styleAIList?.map((entry, i) => (
                            <div className={style["mb-2"]} key={i}>
                              <DropdownItem header>{entry.name}</DropdownItem>

                              {entry.options.map(option => (
                                <DropdownItem
                                  tag="a"
                                  key={option.name}
                                  className={cx({
                                    [style["active"]]: option.name === formFields.styleType,
                                  })}
                                  onClick={() => updateFormData({ type: "styleType", value: option.name })}>
                                  {option.name}
                                </DropdownItem>
                              ))}
                            </div>
                          ))}
                      </div>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
                <div className={cx(style["line-controls-wrap"], style["at-time"])}>
                  <div className={cx(style["slidelabel"], style["mr-0"])}>Images at a time</div>

                  <UncontrolledDropdown className={style["border-style"]}>
                    <DropdownToggle>
                      {formFields.defaultImageAtTime}
                      <Icon icon="ui-arrow-down" />
                    </DropdownToggle>

                    <DropdownMenu className={cx(style["shadow-sm"], style["border-0"], style["rounded"])}>
                      {imageAtTime.map((value, i) => (
                        <DropdownItem
                          tag="a"
                          key={i}
                          onClick={() => updateFormData({ type: "defaultImageAtTime", value: value })}
                          className={cx({
                            [style["active"]]: value === formFields.defaultImageAtTime,
                          })}>
                          {value}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
                <div className={cx(style["mt-auto"], style["text-center"], style["fixed-bottom"])}>
                  <Button
                    type="submit"
                    color="primary"
                    className={style["btn-block"]}
                    cssModule={style}
                    disabled={charCount === 0}>
                    {collectionId ? "Generate More" : "Generate Images"}
                  </Button>
                </div>
              </Form>
            </div>
          )}
        </>
      )}
    </>
  );
};
//Props type validation
AIImageGenerator.propTypes = {
  collectionId: PropTypes.string,
  setCollectionId: PropTypes.func.isRequired,
  assetType: PropTypes.string.isRequired,
  sectionName: PropTypes.object.isRequired,
  setSectionName: PropTypes.func.isRequired,
};

const AIPhotoAsset = ({ photo, assetType, index }) => {
  const imgRef = useRef();
  const { hdPhoto, sdPhoto } = photo;

  const { metadata, dimension } = useContext(EditorContext);
  const { start: initDragDropWidget } = useDragDropWidget();

  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [isHdImageLoaded, setIsHdImageLoaded] = useState(false);
  const [assetInnerContent, setAssetInnerContent] = useState();
  const [widgetWidth, setWidgetWidth] = useState();
  const [widgetHeight, setWidgetHeight] = useState();
  const [dataParam, setDataParam] = useState();
  const [addWidgetClicked, setAddWidgetClicked] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  const imageEventHandler = (e, type) => {
    setIsImageLoaded(true);
    if (type === "error") e.target.src = NoPreview;
    if (type === "load" && e.target.src === hdPhoto) {
      // triggers when hdPhoto loaded from ".hdPhoto-renderer" img element, so that it can be immediately displayed in hover preview AND droped into the canvas without any delay!
      setIsHdImageLoaded(true);
    }
  };

  const setTextFocusOut = useTextFocusOut();
  const { postion: dropPosition } = useAlignment(addWidgetClicked, widgetWidth, widgetHeight, "middle-center");
  const getNewWidgetObject = useAddWidget(
    addWidgetClicked,
    assetType,
    dataParam,
    dropPosition,
    widgetWidth,
    widgetHeight
  );
  useElementInnerHtml(
    addWidgetClicked,
    setAddWidgetClicked,
    assetType,
    assetInnerContent,
    dataParam,
    getNewWidgetObject
  );

  const insertElem = async () => {
    let paramObj = {
      "data-source": AI,
      "data-origin": "stable-diffusion",
    };

    const { width: imgWidth, height: imgHeight } = await getImgDimensionFromUrl(hdPhoto);
    const imgAspectRatio = imgWidth / imgHeight;
    let { width: activeBlockWidth } = document.getElementById(`${metadata.activeBlockId}`)?.getBoundingClientRect();
    activeBlockWidth = (activeBlockWidth * 100) / parseFloat(dimension.zoom);
    const width = imgWidth <= activeBlockWidth ? imgWidth : activeBlockWidth;
    const height = imgWidth <= activeBlockWidth ? imgHeight : width / imgAspectRatio;

    setAssetInnerContent(hdPhoto);
    setWidgetWidth(width);
    setWidgetHeight(height);
    setDataParam({ ...widgetConfig[assetType].dataAttr, ...paramObj });
  };

  const widgetAddOnClickHandler = e => {
    const isLeftClick = e.button === 0;

    // NOTE:::: Only when left mouse button is pressed i.e. MouseEvent.button = 0
    if (isLeftClick) {
      if (document.querySelector(".dhp-content-editable-true-text")) setTextFocusOut(true);
      if (isImageLoaded) insertElem();
    }
  };

  const widgetAddOnDNDHandler = e => {
    const isLeftClick = e.button === 0;

    // NOTE:::: Only when left mouse button is pressed i.e. MouseEvent.button = 0
    if (isLeftClick) {
      initDragDropWidget(
        {
          asset: assetType,
          value: hdPhoto,
          source: AI,
          origin: "stable-diffusion",
        },
        e
      );
    }
  };

  const scrollLeftPane = () => {
    setTooltipOpen(false);
  };

  // trigger widget INSERT
  useEffect(() => {
    if ((assetInnerContent, widgetWidth && widgetHeight && dataParam)) {
      setAddWidgetClicked(true);
    }
  }, [assetInnerContent, widgetWidth, widgetHeight, dataParam]);

  useEffect(() => {
    if (imgRef.current.complete) setIsImageLoaded(true);
  }, []);

  useEffect(() => {
    document.querySelector(".assets-wrapper").addEventListener("scroll", scrollLeftPane);

    return () => {
      document.querySelector(".assets-wrapper")?.removeEventListener("scroll", scrollLeftPane);
    };
  }, []);

  return (
    <>
      {!isImageLoaded && <AssetsLoader count={1} />}

      <li
        className={cx(style["col"], style["skeleton-loader-area"], {
          [style["d-none"]]: !isImageLoaded,
        })}>
        <div
          id={`asset-image-${index}`}
          className={cx(style["asset-item"], {
            [style["loader-overlay"]]: isImageLoaded && !isHdImageLoaded,
          })}
          onClick={e => widgetAddOnClickHandler(e)}
          onMouseDown={e => widgetAddOnDNDHandler(e)}>
          <div className={style["item-holder"]}>
            <img
              ref={imgRef}
              className={style["img-fluid"]}
              src={sdPhoto ? `${sdPhoto}?hash=${Date.now()}` : NoPreview}
              alt="preview thumbnail"
              onLoad={e => imageEventHandler(e, "load")}
              onError={e => imageEventHandler(e, "error")}
            />
            <div className={cx(style["d-none"], "hdPhoto-renderer")}>
              <img src={hdPhoto} onLoad={e => imageEventHandler(e, "load")} />
            </div>
          </div>
        </div>

        {isImageLoaded && (
          <Tooltip
            className="asset-hover-tooltip"
            placement="right"
            isOpen={tooltipOpen}
            target={`asset-image-${index}`}
            toggle={toggle}
            boundariesElement={document.getElementById("app")}>
            <div className="image-preview preview-picture">
              <img src={hdPhoto} alt="" className={style["img-fluid"]} />
            </div>
          </Tooltip>
        )}
      </li>
    </>
  );
};
//Props type validation
AIPhotoAsset.propTypes = {
  photo: PropTypes.object.isRequired,
  assetType: PropTypes.string.isRequired,
  index: PropTypes.number,
};

const AIImageCollection = ({ collectionId, setCollectionId, setSectionName }) => {
  const loadMoreRef = useRef();
  const aiPhotoListRef = useRef();
  const dispatch = useDispatch();
  const showUpgrade = useContextualUpgrade();
  const paidCompanyInfo = useCheckCompanyPlanInfo(true);

  let hasMore = false;
  const IntersectOptions = {
    root: null,
    rootMargin: "0px",
    threshold: 0,
  };
  const fetch = {
    page: 1,
    limit: 50,
    keyword: "",
  };
  const NO_RESULTS = "No record found";

  const [payloadFetch, setPayloadFetch] = useState(fetch);
  const [availablePhotos, setAvailablePhotos] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [collectionList, setCollectionList] = useState({});
  const [loadingList, setLoadingList] = useState(true);
  const [collectionLoader, setCollectionLoader] = useState(true);

  const {
    data: newPhotos = [],
    used: usedCredits = undefined,
    total_count: totalCount = 0,
    total_collection: totalCollection = 0,
    message = "",
  } = collectionList ?? { data: [], used: undefined, total_count: 0, total_collection: 0, message: "" };

  const toggle = () => setShowModal(prevState => !prevState);
  const fetchRecords = () => {
    setLoadingList(true);
    dispatch(fetchTextToImageList(payloadFetch))
      .then(res => {
        if (res.status === 200) {
          setCollectionList(res.data);
          setLoadingList(false);
          setCollectionLoader(false);
        }
      })
      .catch(() => {
        setLoadingList(false);
        setCollectionLoader(false);
      });
  };

  const loadMore = () => setPayloadFetch({ ...payloadFetch, page: payloadFetch.page + 1 });

  const handleIntersect = entries => {
    if (entries[0].isIntersecting && hasMore) loadMore();
  };

  const upgradeSubscriptionCheck = () => {
    if (paidCompanyInfo?.companyRole === COMPANY_SUPERADMIN && !paidCompanyInfo?.isPaid) {
      // Call showUpgrade function to show contexttual upgrade modal based on your condition
      showUpgrade("aiImage");
    } else {
      setCollectionId();
      setSectionName({ id: "generator", name: "AI Images" });
    }
  };

  const applyDebounce = debounce(keyword => {
    setPayloadFetch({ ...payloadFetch, keyword, page: 1 });
  }, 500);

  const handleCollectionDetail = id => {
    setCollectionId(id);
    setSectionName({ id: "generator", name: "AI Images" });
  };

  const handleDeleteCollection = id => {
    setCollectionId(id);
    setShowModal(true);
  };

  const deleteHandler = () => {
    if (collectionId) {
      dispatch(deleteTextToImageCollection(collectionId)).then(r => {
        if (r.status === 200) {
          const updatedPhotos = availablePhotos.filter(p => p.id !== collectionId);
          setAvailablePhotos(updatedPhotos);
          setCollectionId();
          if (updatedPhotos.length === 0) fetchRecords();
        }
      });
    }
  };

  useEffect(() => {
    if (!newPhotos && message === NO_RESULTS) setAvailablePhotos([]);
    if (newPhotos) setAvailablePhotos(payloadFetch.page > 1 ? [...availablePhotos, ...newPhotos] : newPhotos);

    if (totalCount > 0) {
      const lastPage = Math.floor(
        totalCount % payloadFetch.limit === 0 ? totalCount / payloadFetch.limit : totalCount / payloadFetch.limit + 1
      );
      hasMore = payloadFetch.page < lastPage;
    }

    const observer = new IntersectionObserver(handleIntersect, IntersectOptions);
    if (loadMoreRef.current) observer.observe(loadMoreRef.current);

    return () => {
      if (loadMoreRef.current) observer.unobserve(loadMoreRef.current);
    };
  }, [collectionList]);

  useEffect(() => {
    fetchRecords();
  }, [payloadFetch]);

  useEffect(() => {
    setCollectionLoader(true);
    return () => stopAllResourceBuffering(aiPhotoListRef.current, "img");
  }, []);

  return (
    <>
      <div className={cx(style["generate-collection"], style["mr-20"])}>
        <Button
          type="submit"
          color="primary"
          className={style["btn-border"]}
          cssModule={style}
          disabled={false}
          onClick={() => upgradeSubscriptionCheck()}>
          <Icon icon="ui-sparkling" additionalclass="mr-2 font-20" />
          Generate
          <span className={cx(style["crown-icon"], style["ml-2"])}>
            <img src={crown} width="20" className={style["img-fluid"]} alt="Upgrade" />
          </span>
        </Button>
        <span className={style["mt-2"]}>Turn your words into images with AI image generator</span>
      </div>

      {paidCompanyInfo?.isPaid && usedCredits >= 0 && !collectionLoader && (
        <div className={cx(style["mr-20"], style["mt-2"], style["ai-info-link"])}>
          <Link
            to="#"
            className={cx(
              style["d-flex"],
              style["justify-content-end"],
              style["align-items-center"],
              style["color-33"]
            )}>
            {`Images: ${usedCredits}/100`} <Icon icon="information" additionalclass="ml-2 font-base" />
          </Link>
          <div className={cx(style["info-link-content"], style["rounded"], style["shadow-sm"])}>
            {" "}
            100 AI images per month
          </div>
        </div>
      )}

      {collectionLoader && (
        <>
          <div className={cx(style["mr-20"], style["mt-4"])}>
            <CommonLineLoader width={100} height="35px" loaderClass="pb-3" sourceComponent="editor" />
            <CommonLineLoader width={100} height="35px" loaderClass="pb-3" sourceComponent="editor" />
          </div>
          <div className={cx(style["assets-wrapper"], style["pr-0"], style["mt-3"])}>
            <ul className={cx(style["row"], style["row-cols-2"])}>
              <AssetsLoader count={payloadFetch.limit} />
            </ul>
          </div>
        </>
      )}

      {paidCompanyInfo?.isPaid && totalCollection > 0 && !collectionLoader && (
        <div className={cx(style["display-collection"], style["pt-3"])}>
          <p className={cx(style["label"], style["border-bottom"], style["mr-20"], style["pb-1"])}>All Collections</p>
          <div className={cx(style["search-field"], style["p-0"], style["pr-20"], style["mb-3"], style["mr-0"])}>
            <div className={cx(style["search-area"], style["clearfix"])}>
              <span className={style["searchicon"]}>
                <Icon icon="ui-search"></Icon>
              </span>
              <RInput
                type="text"
                id="aiCollectionSearch"
                className={style["form-control"]}
                placeholder={`Search Collections`}
                autoComplete="off"
                onChange={e => applyDebounce(e.target.value.trim())}
              />
            </div>
          </div>

          {!loadingList && message === NO_RESULTS && (
            <div className={cx(style["alert"], style["alert-block"], style["mr-4"], style["alert-danger"])}>
              No results found
            </div>
          )}

          <div className={cx(style["customScroll"], style["scroll-Y"], style["assets-wrapper"])}>
            {loadingList && payloadFetch.page === 1 && (
              <div className={cx(style["assets-wrapper"], style["pr-0"])}>
                <ul className={cx(style["row"], style["row-cols-2"])}>
                  <AssetsLoader count={payloadFetch.limit} />
                </ul>
              </div>
            )}

            {!(loadingList && payloadFetch.page === 1) && (
              <ul className={cx(style["row"], style["row-cols-2"])} ref={aiPhotoListRef}>
                {availablePhotos?.map(photo => (
                  <AIPhotoCollectionThumb
                    key={photo.id}
                    photo={photo}
                    handleCollectionDetail={handleCollectionDetail}
                    handleDeleteCollection={handleDeleteCollection}
                  />
                ))}
              </ul>
            )}

            {!loadingList && <div style={{ height: "20px" }} ref={loadMoreRef}></div>}

            {loadingList && payloadFetch.page > 1 && <DotLoader />}
          </div>

          {showModal && (
            <Modal
              showModal={showModal}
              setShowModal={setShowModal}
              component={DeleteElemModal}
              modalTitle={"Delete"}
              modalBody={"Are you sure to delete this collection? This action can’t be reversed."}
              modalButtonLabel={"Delete"}
              deleteElem={deleteHandler}
              closeModal={toggle}
            />
          )}
        </div>
      )}
    </>
  );
};
//Props type validation
AIImageCollection.propTypes = {
  collectionId: PropTypes.string,
  setCollectionId: PropTypes.func.isRequired,
  setSectionName: PropTypes.func.isRequired,
};

const AIPhotoCollectionThumb = ({ photo, handleCollectionDetail, handleDeleteCollection }) => {
  const imgRef = useRef();
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const imageEventHandler = (e, type) => {
    setIsImageLoaded(true);
    if (type === "error") e.target.src = NoPreview;
  };

  useEffect(() => {
    if (imgRef.current.complete) setIsImageLoaded(true);
  }, []);

  return (
    <>
      {!isImageLoaded && <AssetsLoader count={1} />}

      <li
        className={cx(style["col"], style["skeleton-loader-area"], {
          [style["d-none"]]: !isImageLoaded,
        })}>
        <div className={cx(style["asset-item"])}>
          <div className={style["item-holder"]}>
            <img
              ref={imgRef}
              className={style["img-fluid"]}
              src={photo.thumb ?? NoPreview}
              alt="preview thumbnail"
              onLoad={e => imageEventHandler(e, "load")}
              onError={e => imageEventHandler(e, "error")}
              onClick={() => handleCollectionDetail(photo.id)}
              onDragStart={e => e.preventDefault()}
            />
          </div>
          <span
            className={style["delete-img"]}
            onClick={() => {
              handleDeleteCollection(photo.id);
            }}>
            <Icon icon="ui-trash" />
          </span>
        </div>
      </li>
    </>
  );
};
//Props type validation
AIPhotoCollectionThumb.propTypes = {
  photo: PropTypes.object.isRequired,
  handleCollectionDetail: PropTypes.func.isRequired,
  handleDeleteCollection: PropTypes.func.isRequired,
};

const AISectionName = ({ sectionName, setSectionName, handleWidgetAction }) => {
  const handleBack = e => {
    e.preventDefault();

    if (!sectionName.disabled) {
      //Explanation in EditorLayout.js
      handleWidgetAction("picture", "Picture", "pictures", 1, false, "graphics-media", "AI Images");
      setSectionName({ id: "collection", name: "AI Images" });
    }
  };

  return (
    <>
      {!sectionName.hidden && (
        <h6 className={cx(style["mb-4"], style["fw-6"], style["d-flex"], style["align-items-center"], style["pr-4"])}>
          <Link
            to="#"
            className={cx({
              [style["disable"]]: sectionName.disabled,
            })}
            onClick={e => handleBack(e)}>
            <Icon icon="ui-arrow-left" additionalclass="mr-2" />
          </Link>
          <span className={style["text-truncate"]}>{sectionName.name}</span>
        </h6>
      )}
    </>
  );
};
//Props type validation
AISectionName.propTypes = {
  sectionName: PropTypes.object.isRequired,
  setSectionName: PropTypes.func.isRequired,
  handleWidgetAction: PropTypes.func.isRequired,
};

const AIImage = ({ assetType, sectionName, setSectionName }) => {
  const [collectionId, setCollectionId] = useState();

  return (
    <TabPane tabId="2" className={style["active"]}>
      {sectionName.id === "collection" && (
        <AIImageCollection
          collectionId={collectionId}
          setCollectionId={setCollectionId}
          setSectionName={setSectionName}
        />
      )}

      {sectionName.id === "generator" && (
        <AIImageGenerator
          collectionId={collectionId}
          setCollectionId={setCollectionId}
          assetType={assetType}
          sectionName={sectionName}
          setSectionName={setSectionName}
        />
      )}
    </TabPane>
  );
};
//Props type validation
AIImage.propTypes = {
  assetType: PropTypes.string.isRequired,
  sectionName: PropTypes.object.isRequired,
  setSectionName: PropTypes.func.isRequired,
};

const Picture = ({ assetName, assetType, handleWidgetAction }) => {
  const dataObj = JSON.parse(sessionStorage.getItem("dataObj"));
  const schemeTypeArray = ["Stock Photos", "AI Images"];
  const [schemeType, setSchemeType] = useState(dataObj?.schemeType || "Stock Photos");
  const [sectionName, setSectionName] = useState({ id: "collection", name: "" });
  const activeTabIndex = schemeTypeArray.indexOf(schemeType);

  return (
    <div className={cx(style["editor-asset-inner"], style["picture-asset"])}>
      {(schemeType === "Stock Photos" || (schemeType === "AI Images" && sectionName.id === "collection")) && (
        <>
          <AssetName assetName={assetName} handleWidgetAction={handleWidgetAction} />

          <div className={style["pr-20"]}>
            <AssetSchemeTypeNav
              activeTabIndex={activeTabIndex}
              schemeTypeArray={schemeTypeArray}
              setSchemeType={setSchemeType}
            />
          </div>
        </>
      )}

      {schemeType === "AI Images" && sectionName.id === "generator" && (
        <AISectionName
          sectionName={sectionName}
          setSectionName={setSectionName}
          handleWidgetAction={handleWidgetAction}
        />
      )}

      <TabContent>
        {schemeType === "Stock Photos" && (
          <StockPhoto
            assetName={assetName}
            assetType={assetType}
            schemeType={schemeType}
            handleWidgetAction={handleWidgetAction}
          />
        )}
        {schemeType === "AI Images" && (
          <AIImage assetType={assetType} sectionName={sectionName} setSectionName={setSectionName} />
        )}
      </TabContent>
    </div>
  );
};
//Props type validation
Picture.propTypes = {
  assetName: PropTypes.string.isRequired,
  assetType: PropTypes.string.isRequired,
  handleWidgetAction: PropTypes.func.isRequired,
};

export default Picture;
