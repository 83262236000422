import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { EditorContext } from "../containers/editor/EditorLayout";
import {
  PICTURE,
  VIDEO,
  UPLOAD,
  STOCK,
  CROP_TO_SHAPE_LEFT_RIGHT_PADDING,
  CROP_TO_SHAPE_TOP_BOTTOM_PADDING,
  CROP_TO_SHAPE_PANEL_WIDTH,
  CROP_TO_SHAPE_PANEL_HEIGHT,
  CROP_TO_SHAPE_WIDTH,
  CROP_TO_SHAPE_HEIGHT,
  COLLAGE,
  ANIMATION,
  BRAND,
  UPLOAD_VIDEO,
} from "../constants/editor";
import { widgetConfig } from "../components/Editor/editor_config";
import {
  getFileType,
  getHMSToSec,
  getImgDimensionFromUrl,
  getUnScaledValue,
  removeWhiteSpaceBetweenTags,
} from "../_helpers/utils";
import { triggerUnsplashDownload } from "../store/actions/resourcesActions";
import useCollage from "./useCollage";

const useReplaceWidget = () => {
  const dispatch = useDispatch();
  const { deactivateCollageEvent } = useCollage();
  const { metadata, widgets, updateMetadata, updateWidgets, dimension } = useContext(EditorContext);
  const [meta, setMeta] = useState();
  const [styleObj, setStyleObj] = useState();
  const [dataParam, setDataParam] = useState();
  const [innerHTML, setInnerHTML] = useState();
  const [additionalData, setAdditionalData] = useState();

  const replace = {
    compatibility: {
      imageType: {
        assetType: [PICTURE, UPLOAD],
        fileType: ["jpeg", "jpg", "png", "gif", "jfif"],
        selector: "img",
      },
      videoType: {
        assetType: [VIDEO],
        fileType: ["mp4"],
        selector: "video",
      },
      collageType: {
        assetType: [COLLAGE],
        fileType: ["jpeg", "jpg", "png", "gif", "jfif", "mp4"],
        selector: "",
      },
    },

    formatData: (widgetNode, replaceData, detectOnly) => {
      let isGroupWidget = document.getElementById(widgetNode.id).closest(".dhp-page-group");
      let targetId = isGroupWidget
        ? document.getElementById(widgetNode.id).closest(".dhp-root-widget").getAttribute("id")
        : widgetNode.id;
      const oldWidgetIdx = widgets.findIndex(widget => widget.id === targetId); // TODO:::: replace Array.findIndex() with oldWidgetIdx
      const oldWidgetObj = isGroupWidget ? document.getElementById(widgetNode.id) : widgets[oldWidgetIdx];
      const sandbox = document.createElement("div");
      sandbox.innerHTML = oldWidgetObj.innerHTML;

      const oldWidget = {
        assetType: document.getElementById(widgetNode.id).getAttribute("data-asset-type"),
        fileType: getFileType(
          sandbox?.getElementsByTagName("img")[0]?.src ?? sandbox?.getElementsByTagName("video")[0]?.src ?? ""
        ),
        loop: document.getElementById(widgetNode.id).getAttribute("data-loop"),
        duration: document.getElementById(widgetNode.id).getAttribute("data-duration"),
        trimmedDuration:
          document.getElementById(widgetNode.id).getAttribute("data-trimmed-duration") !== ""
            ? document.getElementById(widgetNode.id).getAttribute("data-trimmed-duration")
            : document.getElementById(widgetNode.id).getAttribute("data-duration"),
      };

      const newWidget = {
        assetType: replaceData.asset,
        fileType: getFileType(replaceData.value),
      };

      if (!detectOnly) {
        oldWidget.id = widgetNode.id;
        oldWidget.idx = oldWidgetIdx;
        oldWidget.sandbox = sandbox;
        oldWidget.style = oldWidgetObj.style;
        oldWidget.dataCropToShape = widgetNode.getAttribute("data-crop-to-shape");
        oldWidget.dataLayerLocked = widgetNode.getAttribute("data-layer-locked") === "true" ? true : false;
        newWidget.value = replaceData.value;
        newWidget.poster = replaceData.poster;
      }

      return {
        oldWidget,
        newWidget,
      };
    },

    isWidgetReplaceable: (widgetNode, replaceData, detectOnly) => {
      let replaceType = false;
      const { oldWidget, newWidget } = replace.formatData(widgetNode, replaceData, detectOnly);

      Object.keys(replace.compatibility).forEach(type => {
        if (
          (replace.compatibility[type].assetType.includes(oldWidget.assetType) &&
            replace.compatibility[type].assetType.includes(newWidget.assetType) &&
            replace.compatibility[type].fileType.includes(oldWidget.fileType) &&
            replace.compatibility[type].fileType.includes(newWidget.fileType)) ||
          (oldWidget.assetType === COLLAGE &&
            replace.compatibility.collageType.fileType.includes(newWidget.fileType) &&
            !["YOUTUBE"].includes(replaceData?.source))
        ) {
          replaceType = type;
        }
      });

      return {
        oldWidget,
        newWidget,
        replaceType,
      };
    },

    prepareInnerHtml: async (oldWidget, newWidget, replaceType, childNode, replaceData) => {
      if (["imageType", "videoType"].includes(replaceType)) {
        const sandbox = oldWidget.sandbox;
        const selector = replace.compatibility[replaceType].selector;

        sandbox.querySelectorAll(selector).forEach(node => {
          node.src = newWidget.value;
          if (replaceType === "videoType") node.poster = newWidget.poster;
        });

        return sandbox.innerHTML;
      }

      if (["collageType"].includes(replaceType)) {
        const mediaType =
          Object.keys(replace.compatibility).find(type =>
            replace.compatibility[type].assetType.includes(newWidget.assetType)
          ) ?? "imageType";

        const thumb =
          mediaType === "imageType" ? (replaceData.srcSet ? replaceData.srcSet.s3 : newWidget.value) : newWidget.poster;

        const { height: imageHeight, width: imageWidth } = await getImgDimensionFromUrl(thumb);

        const childRect = childNode.getBoundingClientRect();
        const frameWidth = getUnScaledValue(childRect.width, dimension.zoom);
        const frameHeight = getUnScaledValue(childRect.height, dimension.zoom);

        const factor = Math.max(frameWidth / imageWidth, frameHeight / imageHeight);
        const pictureWidth = imageWidth * factor;
        const pictureHeight = imageHeight * factor;
        const translateX = (frameWidth - pictureWidth) / 2;
        const translateY = (frameHeight - pictureHeight) / 2;

        const dataCrop = JSON.stringify({
          n: Math.abs(translateY),
          s: Math.abs(translateY),
          w: Math.abs(translateX),
          e: Math.abs(translateX),
        });

        const mediaNode =
          mediaType === "imageType"
            ? `<img
                src="${thumb}"
                class="media-node"
                style="height: 100%; width: 100%; display: block;" />`
            : `<video
                poster="${thumb}"
                src="${newWidget.value}" muted="true"
                playsinline="true"
                disablepictureinpicture="true"
                preload="auto"
                class="media-node"
                style="height: 100%; width: 100%; display: block; object-fit: cover;">
              </video>`;

        const mediaWrapper = removeWhiteSpaceBetweenTags(`
          <div
            class="media-grand-parent"
            style="overflow: hidden; width: ${frameWidth}px ; height: ${frameHeight}px;">
            <div
                class="media-parent"
                style="display: block; opacity: 1; width: ${pictureWidth}px; height: ${pictureHeight}px; will-change: transform; transform-origin: left top; transform: translate(${translateX}px, ${translateY}px) scale(1, 1);"
                data-crop=${dataCrop}>
                  ${mediaNode}
            </div>
          </div>
        `);

        return mediaWrapper;
      }
    },

    prepareNewWidgetDimension: async (oldWidget, newWidget, replaceType) => {
      const { height: imageHeight, width: imageWidth } = await getImgDimensionFromUrl(
        replaceType === "imageType" ? newWidget.value : newWidget.poster
      );
      let oldWidgetInner = oldWidget.sandbox.querySelector(".dhp-widget-inner");
      let oldWidgetDia = document.getElementById(`${oldWidget.id}`);
      let oldHeight = oldWidgetDia.clientHeight;
      let oldWidth = oldWidgetDia.clientWidth;

      let factor = Math.max(oldWidth / imageWidth, oldHeight / imageHeight);
      let newInnerHeight = imageHeight * factor;
      let newInnerWidth = imageWidth * factor;
      let translateX = (oldWidth - newInnerWidth) / 2;
      let translateY = (oldHeight - newInnerHeight) / 2;

      if (oldWidget.dataCropToShape === "true") {
        let factor = Math.max(CROP_TO_SHAPE_PANEL_WIDTH / imageWidth, CROP_TO_SHAPE_PANEL_HEIGHT / imageHeight);
        let newInnerHeight = imageHeight * factor;
        let newInnerWidth = imageWidth * factor;
        oldWidgetInner.style.width = `${CROP_TO_SHAPE_WIDTH}px`;
        oldWidgetInner.style.height = `${CROP_TO_SHAPE_HEIGHT}px`;
        let transformScale = oldHeight / CROP_TO_SHAPE_HEIGHT;
        oldWidgetInner.style.transform = `translate(px, 0px) scale(${transformScale}, ${transformScale})`;
        oldWidgetInner.children[0].style.height = `${newInnerHeight}px`;
        oldWidgetInner.children[0].style.width = `${newInnerWidth}px`;
        oldWidgetInner.children[0].style.left = `${(CROP_TO_SHAPE_WIDTH - newInnerWidth) / 2}px`;
        oldWidgetInner.children[0].style.top = `${(CROP_TO_SHAPE_HEIGHT - newInnerHeight) / 2}px`;
        // Auto align image center in crop to shape modal
        let newDataLeft = `${(CROP_TO_SHAPE_PANEL_WIDTH - newInnerWidth + CROP_TO_SHAPE_LEFT_RIGHT_PADDING) / 2}px`;
        let newDataTop = `${(CROP_TO_SHAPE_PANEL_HEIGHT - newInnerHeight + CROP_TO_SHAPE_TOP_BOTTOM_PADDING) / 2}px`;
        oldWidgetInner.setAttribute("data-width", newInnerWidth);
        oldWidgetInner.setAttribute("data-height", newInnerHeight);
        oldWidgetInner.setAttribute("initial-data-width", `${newInnerWidth}px`);
        oldWidgetInner.setAttribute("initial-data-height", `${newInnerHeight}px`);
        oldWidgetInner.setAttribute("data-left", newDataLeft);
        oldWidgetInner.setAttribute("data-top", newDataTop);
      } else {
        oldWidgetInner.style.height = `${newInnerHeight}px`;
        oldWidgetInner.style.width = `${newInnerWidth}px`;
        oldWidgetInner.style.transform = `translate(${translateX}px, ${translateY}px) scale(1, 1)`;
        let flippableStyle = oldWidget.sandbox.querySelector(".flippable").style;
        if (flippableStyle?.borderStyle && flippableStyle.borderStyle !== "none") {
          let borderWidth = parseInt(flippableStyle.borderWidth);
          oldWidgetInner.style.left = `${-borderWidth}px`;
          oldWidgetInner.style.top = `${-borderWidth}px`;
          oldWidgetInner.style.position = "relative";
        } else {
          oldWidgetInner.style.left = "";
          oldWidgetInner.style.top = "";
          oldWidgetInner.style.position = "";
        }
        oldWidgetInner.dataset.crop = JSON.stringify({
          n: Math.abs(translateY),
          s: Math.abs(translateY),
          w: Math.abs(translateX),
          e: Math.abs(translateX),
        });
      }
      return oldWidget.style;
    },

    prepareDataParam: (data, oldWidget, replaceType) => {
      const paramObj = {};
      const { asset, value, source, origin, download, poster, duration, filename } = data;

      // get dynamic data params
      if ([PICTURE].includes(asset)) {
        paramObj["data-source"] = source;
        paramObj["data-origin"] = origin;
        paramObj["data-download"] = download;
      }
      if ([VIDEO].includes(asset) && [STOCK, BRAND, UPLOAD_VIDEO].includes(source)) {
        paramObj["data-origin"] = origin;
        paramObj["data-poster"] = poster;
        paramObj["data-url"] = value;
        paramObj["data-duration"] = duration;

        if (!["collageType"].includes(replaceType)) {
          let newTrimmedDuration =
            parseFloat(getHMSToSec({ hms: duration })) > parseFloat(getHMSToSec({ hms: oldWidget.duration }))
              ? oldWidget.trimmedDuration
              : parseFloat(getHMSToSec({ hms: oldWidget.trimmedDuration })) < parseFloat(getHMSToSec({ hms: duration }))
              ? oldWidget.trimmedDuration
              : duration;

          paramObj["data-loop"] = oldWidget.loop;
          paramObj["data-trimmed-duration"] = newTrimmedDuration;
          paramObj["data-trim"] = `0,${parseFloat(getHMSToSec({ hms: newTrimmedDuration }))}`;
        }
      }
      if ([UPLOAD].includes(asset)) {
        paramObj["data-file-type"] = getFileType(filename);
        paramObj["data-duration"] = duration;
      }
      if ([ANIMATION].includes(asset)) {
        paramObj["data-duration"] = duration;
      }

      paramObj["data-crop-to-shape"] = oldWidget.dataCropToShape;
      paramObj["data-layer-locked"] = oldWidget.dataLayerLocked;

      // merge dynamic and predefined data params
      if ([VIDEO].includes(asset)) {
        return { ...widgetConfig[asset][source].dataAttr, ...paramObj };
      } else {
        return { ...widgetConfig[asset].dataAttr, ...paramObj };
      }
    },

    start: async (widgetNode, replaceData, childNode) => {
      const { oldWidget, newWidget, replaceType } = replace.isWidgetReplaceable(widgetNode, replaceData, false);

      if (["imageType", "videoType"].includes(replaceType)) {
        const newStyleObj = await replace.prepareNewWidgetDimension(oldWidget, newWidget, replaceType);
        const newDataParam = replace.prepareDataParam(replaceData, oldWidget);
        const newInnerHTML = await replace.prepareInnerHtml(oldWidget, newWidget, replaceType, false, replaceData);

        setStyleObj(newStyleObj);
        setDataParam(newDataParam);
        setInnerHTML(newInnerHTML);
        setMeta({
          activeWidgetId: [oldWidget.id],
          activeWidgetIdx: oldWidget.idx,
          activeWidgetType: [newWidget.assetType],
        });
        setAdditionalData({ oldWidget, newWidget, replaceType, childNode, replaceData });
      }

      if (["collageType"].includes(replaceType)) {
        const newDataParam = replace.prepareDataParam(replaceData, oldWidget, replaceType);
        const newInnerHTML = await replace.prepareInnerHtml(oldWidget, newWidget, replaceType, childNode, replaceData);

        setStyleObj(true);
        setDataParam(newDataParam);
        setInnerHTML(newInnerHTML);
        setMeta({
          activeWidgetId: [oldWidget.id],
          activeWidgetIdx: oldWidget.idx,
          activeWidgetType: [oldWidget.assetType],
        });
        setAdditionalData({ oldWidget, newWidget, replaceType, childNode, replaceData });
      }
    },

    update: () => {
      let isGroupWidget = document.getElementById(meta.activeWidgetId).closest(".dhp-page-group");
      let targetId = isGroupWidget
        ? document.getElementById(meta.activeWidgetId).closest(".dhp-root-widget").getAttribute("id")
        : meta.activeWidgetId;
      let isVideoTypeWidget =
        [VIDEO].includes(document.getElementById(meta.activeWidgetId).getAttribute("data-asset-type")) &&
        [STOCK, BRAND, UPLOAD_VIDEO].includes(document.getElementById(meta.activeWidgetId).getAttribute("data-source"));

      // replace image for group widget
      if (isGroupWidget) {
        // update Inner widget DOM
        document.getElementById(meta.activeWidgetId).innerHTML = innerHTML;
        Object.keys(dataParam).forEach(key => {
          document.getElementById(meta.activeWidgetId).setAttribute(key, dataParam[key]);
        });

        // update context for main group widget
        updateWidgets(
          widgets.map((widget, idx) => {
            if (idx === meta.activeWidgetIdx) {
              const widgetObj = {
                ...widget,
                innerHTML: document.getElementById(targetId).innerHTML,
              };

              if (additionalData?.replaceData?.srcSet) {
                widgetObj.groupSrcSet = {
                  ...(widgetObj?.groupSrcSet ?? {}),
                  [additionalData?.oldWidget?.id]: additionalData?.replaceData?.srcSet,
                };
              } else if (
                !additionalData?.replaceData?.srcSet &&
                widgetObj?.groupSrcSet?.[additionalData?.oldWidget?.id]
              ) {
                delete widgetObj?.groupSrcSet?.[additionalData?.oldWidget?.id];
              }

              if (widgetObj?.groupSrcSet && Object.keys(widgetObj?.groupSrcSet)?.length === 0) {
                delete widgetObj?.groupSrcSet;
              }

              return widgetObj;
            } else return widget;
          })
        );
      }
      // replace image of collage
      else if (["collageType"].includes(additionalData?.replaceType)) {
        // deactivate collage event (if any) before next action
        deactivateCollageEvent({ e: null, actionName: "SAVE" });

        additionalData.childNode.classList.remove("blank");
        additionalData.childNode.classList.add("filled");
        additionalData.childNode.innerHTML = innerHTML;

        ["data-asset-type", "data-download", "data-origin", "data-source", "data-duration"].forEach(attr => {
          if (dataParam[attr]) {
            additionalData.childNode.querySelector(".media-node").setAttribute(attr, dataParam[attr]);
          }
        });

        updateWidgets(
          widgets.map((widget, idx) =>
            idx === meta.activeWidgetIdx
              ? { ...widget, innerHTML: document.getElementById(targetId).innerHTML }
              : widget
          )
        );
      }
      // replace video type widget
      else if (isVideoTypeWidget) {
        let widgetDurationStartTime = widgets[meta.activeWidgetIdx].duration?.startTime
          ? widgets[meta.activeWidgetIdx].duration.startTime
          : 0;
        let widgetEndTime = parseFloat(getHMSToSec({ hms: dataParam["data-trimmed-duration"] }));
        let isVideoInLoop = widgets[meta.activeWidgetIdx].data["data-loop"];

        if (!isVideoInLoop) {
          updateWidgets(
            widgets.map((widget, idx) =>
              idx === meta.activeWidgetIdx
                ? {
                    ...widget,
                    style: styleObj,
                    duration: {
                      ...widgets[meta.activeWidgetIdx].duration,
                      endTime: `${widgetEndTime}s`,
                      totalTime: `${parseFloat(widgetEndTime) - parseFloat(widgetDurationStartTime)}s`,
                    },
                    data: dataParam,
                    innerHTML: innerHTML,
                  }
                : widget
            )
          );
        } else {
          updateWidgets(
            widgets.map((widget, idx) =>
              idx === meta.activeWidgetIdx
                ? { ...widget, style: styleObj, data: dataParam, innerHTML: innerHTML }
                : widget
            )
          );
        }
      }
      // replace single image
      else {
        updateWidgets(
          widgets.map((widget, idx) => {
            if (idx === meta.activeWidgetIdx) {
              const widgetObj = {
                ...widget,
                style: styleObj,
                data: dataParam,
                innerHTML: innerHTML,
              };

              if (additionalData?.replaceData?.srcSet) widgetObj.srcSet = additionalData?.replaceData?.srcSet;
              else if (!additionalData?.replaceData?.srcSet && widgetObj?.srcSet) delete widgetObj?.srcSet;

              return widgetObj;
            } else return widget;
          })
        );
      }

      // trigger unsplash download
      if (dataParam["data-asset-type"] === PICTURE && dataParam["data-origin"] === "unsplash") {
        dispatch(triggerUnsplashDownload(dataParam["data-download"]));
      }

      updateMetadata({ ...metadata, ...meta });
      setMeta();
      setStyleObj();
      setDataParam();
      setInnerHTML();
      setAdditionalData();
    },
  };

  useEffect(() => {
    if (meta && styleObj && dataParam && innerHTML && additionalData) {
      replace.update();
    }
  }, [meta, styleObj, dataParam, innerHTML, additionalData]);

  return { start: replace.start, isWidgetReplaceable: replace.isWidgetReplaceable };
};

export default useReplaceWidget;
